import React from 'react';
import { ReactSVG } from 'react-svg';
import { PopupButton } from '@typeform/embed-react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './ChallengeModal.module.scss';
import Button from '../Button/Button';
import { postChallengeSubmitAsync } from '../../features/learn/learnSlice';
import { addChallenge, addFinishedChallenge, addPoints } from '../../features/dashboard/dashboardSlice';
import routes from '../../constants/routes';
import { getChallengesDataTemp, saveDataAsync } from '../../features/bamQuestionnaire/screens/screenSlice';

function ChallengeModal({
  onClose,
  display,
  icon,
  title,
  period,
  repeat,
  points,
  description,
  typeformId,
  challengeId,
  onSubmit,
  finished,
  periodName,
  periodLength,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const challengeData = useSelector(getChallengesDataTemp);

  return (
    <div className={styles.main}>
      <div
        className={styles.modalBack}
        role="button"
        onClick={onClose}
        onKeyPress={() => {}}
        tabIndex="-1"
        style={{ display: display ? 'flex' : 'none' }}
      >
        <div
          className={styles.modalWrap}
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={() => {}}
          tabIndex="-1"
          role="button"
        >
          <div className={styles.stick} />
          <div className={styles.icon}>
            {icon}
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.challengeCategory}>
            <ReactSVG className={styles.categoryIcon} src="/challenges/tiles-smaill-icon.svg" />
            quiz
            <div className={styles.divider} />
            <ReactSVG className={styles.categoryIcon} src="/small-clock-icon.svg" />
            {`${periodLength} `}
            {periodName}
          </div>
          <div className={finished ? styles.pointsInfoFinished : styles.pointsInfo}>
            <div className={styles.pointsInfoTitle}>{finished ? 'You earned' : 'You will earn'}</div>
            <div className={finished ? styles.pointsInfoPointsFinished : styles.pointsInfoPoints}>
              <ReactSVG
                className={styles.pointsIcon}
                src={`/challenges/star-${finished ? 'green-' : 'orange-'}filled.svg`}
              />
              {`+${points} points`}
            </div>
          </div>
          <div className={styles.challengeText}>
            {description}
          </div>
          <div className={styles.buttonWrapper}>
            {
              finished ? (
                <Button
                  onClick={onClose}
                >
                  Close
                </Button>
              ) : (
                <PopupButton
                  className={styles.typeformButton}
                  id={typeformId}
                  onSubmit={(event) => {
                    // dispatch(postChallengeSubmitAsync({ challengeId }));
                    // dispatch(addPoints({ points }));
                    dispatch(
                      saveDataAsync({
                        selectedLanguage: JSON.stringify({
                          ...challengeData,
                          points: (challengeData.points || 0) + points,
                          totalPoints: (challengeData.totalPoints || 0) + points,
                          completedNum: (challengeData.completedNum || 0) + 1,
                          completedIds: (challengeData.completedIds || []).concat(challengeId),
                        }),
                      }),
                    );
                    onSubmit(event);
                  }}
                >
                  <Button
                    onClick={() => {}}
                    filled
                  >
                    Start Challenge
                  </Button>
                </PopupButton>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

ChallengeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  period: PropTypes.string,
  repeat: PropTypes.string,
  points: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  typeformId: PropTypes.string.isRequired,
  challengeId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  finished: PropTypes.bool,
  periodName: PropTypes.string.isRequired,
  periodLength: PropTypes.string.isRequired,
};

ChallengeModal.defaultProps = {
  period: null,
  repeat: null,
  finished: false,
  onSubmit: () => {},
};

export default ChallengeModal;
