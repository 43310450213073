import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

function Modal({
  onClose,
  display,
  children,
  height,
}) {
  return (
    <div className={styles.main}>
      <div
        className={styles.modalBack}
        role="button"
        onClick={onClose}
        onKeyPress={() => {}}
        tabIndex="-1"
        style={{ display: display ? 'flex' : 'none' }}
      >
        <div
          className={styles.modalWrap}
          style={{ minHeight: `${height}vh` }}
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={() => {}}
          tabIndex="-1"
          role="button"
        >
          {children}
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  height: PropTypes.number,
};

Modal.defaultProps = {
  height: null,
};

export default Modal;
