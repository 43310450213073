import { apiRequest, apiRequestGet } from '../../helpers/apiRequest';
import methods from '../../constants/httpMethods';

export const getArticles = async (token) => apiRequestGet('/articles', { token });

export const getChallenges = async (token) => apiRequestGet('/user-challenges/active', { token });

export const getCompletedChallenges = async (token) => apiRequestGet('/user-challenges/finished', { token });

export const postChallengeSubmit = async (challengeId, answerId, token) => apiRequest(`/user-challenges/${challengeId}/progress`, methods.POST, { answerId }, { token });

export const getArticle = async (articleId, token) => apiRequestGet(`/articles/${articleId}`, { token });
