import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import styles from './ChallengeBlock.module.scss';

function ChallengeBlock({
  title,
  points,
  periodName,
  periodLength,
  iconPath,
  icon,
  onClick,
  showProgress,
  closed,
  finished,
}) {
  return (
    <div className={styles.challengeBlock} onClick={onClick} onKeyPress={() => {}} tabIndex={-1} role="button">
      <div className={styles.challengeBlockIcon}>{icon || <ReactSVG src={iconPath} />}</div>
      <div className={styles.challengeBlockData}>
        {showProgress && (
          <div>
            <div className={styles.challengeBlockDataProgress}>
              <div className={styles.challengeBlockDataProgressIndicator} style={{ width: '0' }} />
            </div>
          </div>
        )}
        <div className={styles.challengeBlockDataName}>{title}</div>
        <div className={styles.challengeBlockInfo}>
          <div className={styles.challengeBlockInfoItem}>
            <ReactSVG
              className={styles.challengeBlockInfoItemIcon}
              src={`/challenges/star-small-${finished ? 'green-' : ''}${closed ? 'empty' : 'filled'}.svg`}
            />
            {`${points} points${finished ? ' earned' : ''}`}
          </div>
          {!finished && <div className={styles.divider} />}
          {periodLength && (
            <div className={styles.challengeBlockInfoItem}>
              <ReactSVG className={styles.challengeBlockInfoItemIcon} src="/small-clock-icon.svg" />
              {`${periodLength} `}
              {periodName}
            </div>
          )}
        </div>
      </div>
      {
        !finished && (
          <div className={styles.arrow}>
            <ReactSVG src="/challenges/arrow-right.svg" />
          </div>
        )
      }
    </div>
  );
}

ChallengeBlock.propTypes = {
  title: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  periodName: PropTypes.string,
  periodLength: PropTypes.number,
  iconPath: PropTypes.string,
  onClick: PropTypes.func,
  showProgress: PropTypes.bool,
  closed: PropTypes.bool,
  icon: PropTypes.string,
  finished: PropTypes.bool,
};

ChallengeBlock.defaultProps = {
  onClick: () => null,
  periodName: null,
  periodLength: null,
  showProgress: false,
  closed: false,
  icon: null,
  iconPath: null,
  finished: false,
};

export default ChallengeBlock;
