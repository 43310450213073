import requestStatuses from '../constants/requestStatuses';

export default (builder, func, statusName, dataName) => builder
  .addCase(func.pending, (state) => {
    state[statusName] = requestStatuses.LOADING;
  })
  .addCase(func.fulfilled, (state, action) => {
    const { success, data } = action.payload;
    if (success) {
      state[statusName] = requestStatuses.IDLE;
      if (dataName) {
        state[dataName] = data;
      }
    } else {
      state[statusName] = requestStatuses.ERROR;
    }
  });
