import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import PageBack from '../PageBack/PageBack';
import styles from './SuccessScreenGreen.module.scss';
import PageWrapper from '../PageWrapper/PageWrapper';
import HeaderNav from '../HeaderNav/HeaderNav';
import ButtonWrapper from '../ButtonWrapper/ButtonWrapper';
import Button from '../Button/Button';

function SuccessScreenGreen({
  onBack,
  onClick,
  title,
  description,
  buttonText,
  show,
  autoClose,
  customIcon,
  additionalContent,
}) {
  const [closeTimer, setCloseTimer] = useState(null);

  const startCloseTimer = () => {
    if (!closeTimer) {
      setCloseTimer(
        setTimeout(() => {
          onClick();
          setCloseTimer(null);
        }, 2500),
      );
    }
  };

  if (autoClose && show) {
    startCloseTimer();
  }

  return (
    <PageBack className={show ? styles.pageBack : styles.pageBackHidden}>
      <PageWrapper>
        <div className={styles.wrap}>
          {onBack && (
            <HeaderNav onBack={onBack} title="" />
          )}
          <div className={styles.icon}>
            <ReactSVG src={customIcon || '/success/success-logo-green.svg'} />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            {additionalContent}
          </div>
          <ButtonWrapper>
            <Button onClick={onClick} transparent>{buttonText}</Button>
          </ButtonWrapper>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

SuccessScreenGreen.propTypes = {
  onBack: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  show: PropTypes.bool,
  autoClose: PropTypes.bool,
  customIcon: PropTypes.string,
  additionalContent: PropTypes.element,
};

SuccessScreenGreen.defaultProps = {
  show: false,
  onBack: null,
  autoClose: false,
  customIcon: null,
  additionalContent: null,
};

export default SuccessScreenGreen;
