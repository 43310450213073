import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import readingTime from 'reading-time';
import { getSingleArticleAsync, selectArticleData } from '../learnSlice';
import routes from '../../../constants/routes';
import PageBack from '../../../components/PageBack/PageBack';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';
import styles from './Article.module.scss';
import requestStatuses from '../../../constants/requestStatuses';
import Scroll from '../../../components/Scroll/Scroll';
import { S3_IMG_URL } from '../../../config';
import HeaderNav from '../../../components/HeaderNav/HeaderNav';
import DividerRound from '../../../components/DividerRound/DividerRound';

function Article() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { articleId } = useParams();

  if (!articleId) {
    return <Redirect to={`${routes.LEARN}?tab=1`} />;
  }
  const { article, status } = useSelector(selectArticleData);

  if (!status || (status === requestStatuses.IDLE && parseInt(articleId) !== article?.id)) {
    dispatch(getSingleArticleAsync({ articleId }));
  }

  return (
    <PageBack>
      <PageWrapper>
        <HeaderNav onBack={() => history.goBack()} title="Article" />
        <div className={styles.title}>
          {article?.title}
        </div>
        <div className={styles.articleInfo}>
          {`${Math.round(readingTime(article?.content || '').minutes)} `}
          min read
          <DividerRound color={article?.category?.color} />
          <div
            className={styles.category}
            style={{ color: article?.category?.color }}
          >
            {` ${article?.category?.name || 'Vitaance'}`}
          </div>
        </div>
        <div className={styles.articleWrapper}>
          <Scroll showUpButton>
            <img src={`${S3_IMG_URL}/${article?.imageId}`} alt="Article" />
            <ReactMarkdown>
              {article?.content || ''}
            </ReactMarkdown>
            <div className={styles.bottomEmpty} />
          </Scroll>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default Article;
