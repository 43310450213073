import React from 'react';
import PropTypes from 'prop-types';
import styles from './DividerRound.module.scss';

function DividerRound({ className, color }) {
  return <div className={`${styles.divider} ${className}`} style={{ background: color }} />;
}

DividerRound.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

DividerRound.defaultProps = {
  className: '',
  color: null,
};

export default DividerRound;
