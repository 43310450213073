import React from 'react';
import { ReactSVG } from 'react-svg';
import { WhiteSpace } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import 'antd-mobile/dist/antd-mobile.css';
import styles from './Welcome.module.css';
import PageBack from '../../components/PageBack/PageBack';
import ButtonMain from '../../components/Button/Button';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

function Welcome() {
  const history = useHistory();

  return (
    <PageBack>
      <PageWrapper>
        <div className={styles.wrap}>
          <div className={styles.empty1} />
          <div className={styles.logoWrapper}>
            <ReactSVG className={styles.logo} src="/logo.svg" />
            <WhiteSpace size="xl" />
            <div className={styles.logoText}>Live better. Live more.</div>
          </div>
          <div className={styles.buttons}>
            <ButtonMain
              transparent
              onClick={() => history.push('/login')}
            >
              Log-in
            </ButtonMain>
            <WhiteSpace size="sm" />
            <ButtonMain
              filled
              onClick={() => history.push('/registration')}
            >
              Create account
            </ButtonMain>
          </div>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default Welcome;
