import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import {useDispatch, useSelector} from 'react-redux';
import styles from './SmokeScreen.module.scss';
import SelectButtons from '../../../../components/SelectButtons/SelectButtons';
import {saveDataAsync, selectProfile} from '../screenSlice';
import Button from '../../../../components/Button/Button';

const smokeOptions = {
  no: 'No, I never have',
  'used-to': 'No, but I used to',
  'time-to-time': 'From time to time. I am a social smoker',
  yes: 'Yes, regularly',
};

function SmokeScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [selectedValue, setValue] = useState(me?.smoking);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <div className={styles.contentWrapper}>
        <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
          <div className={styles.navBarCustomLeft}>
            <Icon color="#30353F" type="left" />
            <div className={styles.navBarCustomText}>Back</div>
          </div>
          <div className={styles.navBarCustomCenter}>6/12</div>
          <div className={styles.navBarCustomRight} />
        </div>
        <div>
          <Progress
            className={styles.progress}
            barStyle={{
              border: '4px solid #9500AD',
              borderRadius: '4px',
            }}
            percent={49.8}
            position="normal"
          />
        </div>
        <div className={styles.imageWrap}>
          <img className={styles.image} src="/smoke-img.png" alt="Smoke" />
        </div>
        <div className={styles.question}>
          Do you smoke?
        </div>
        <div className={styles.description}>
          This will help us understand you better
        </div>
        <div className={styles.inputWrapper}>
          <SelectButtons
            options={Object.keys(smokeOptions).map((value) => ({ value, name: smokeOptions[value] }))}
            onSelect={setValue}
            selected={selectedValue}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ smoking: selectedValue }));
          }}
          disabled={!selectedValue}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

SmokeScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  data: PropTypes.shape({ name: PropTypes.string }),
};

SmokeScreen.defaultProps = {
  data: { name: 'User' },
};

export default SmokeScreen;
