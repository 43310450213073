import React from 'react';
import PropTypes from 'prop-types';
import styles from './BottomDialog.module.scss';

function BottomDialog({ children, show, onClose }) {
  return (
    <div
      className={styles.dialogWrap}
      style={{ ...(show ? {} : { display: 'none' }) }}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      onKeyPress={() => {}}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.dialog}>
        {children}
      </div>
    </div>
  );
}

BottomDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BottomDialog.defaultProps = {
  show: false,
};

export default BottomDialog;
