import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import styles from './AboutScreen.module.scss';
import Button from '../../../../components/Button/Button';

function AboutScreen({ onNextStep }) {
  return (
    <div className={styles.wrap}>
      <div className={styles.contentWrapper}>
        <div className={styles.logoWrapper}>
          <ReactSVG className={styles.logo} src="/bam/question-icon.svg" />
        </div>
        <div className={styles.title}>Health Questionnaire</div>
        <div className={styles.content}>
          Please take a short survey about your health and habits.
          This will help us find the right plan for you and we will be able to form useful habits that you can follow.
          Answer honestly, please.
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            onNextStep();
            // TODO: send data here
          }}
        >
          Start Quiz
        </Button>
      </div>
    </div>
  );
}

AboutScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
};

export default AboutScreen;
