import { createSlice } from '@reduxjs/toolkit';

export const dashboardActions = {
  ADD_POINTS: 'dash/addPoints',
  ADD_CHALLENGES: 'dash/addChallenges',
};

const initialState = {
  points: localStorage.getItem('points') || 0,
  completedChallenges: localStorage.getItem('challenges') || 0,
  finishedChallenges: localStorage.getItem('challenges_finished') || '',
};

export const dashSlice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    addPoints: (state, action) => {
      const { points: sPoints } = state;
      const { points } = action.payload;
      const newPoints = parseInt(sPoints, 10) + parseInt(points, 10);
      localStorage.setItem('points', newPoints.toString());
      state.points = newPoints;
    },
    addChallenge: (state) => {
      const { completedChallenges } = state;
      const newNumber = parseInt(completedChallenges, 10) + 1;
      localStorage.setItem('challenges', newNumber.toString());
      state.completedChallenges = newNumber;
    },
    addFinishedChallenge: (state, action) => {
      const { finishedChallenges } = state;
      const { challengeId } = action.payload;
      localStorage.setItem('challenges_finished', `${finishedChallenges},${challengeId}`);
      state.finishedChallenges = `${finishedChallenges},${challengeId}`;
    },
    clearStorage: (state) => {
      // TODO: remove this!!!
      localStorage.clear();
      state.points = 0;
      state.completedChallenges = 0;
      state.finishedChallenges = '';
    },
  },
});


export const selectPoints = (state) => state.dash.points;

export const selectChallenges = (state) => state.dash.completedChallenges;

export const selectFinishedChallenges = (state) => state.dash.finishedChallenges;

export const {
  addPoints, addChallenge, addFinishedChallenge, clearStorage,
} = dashSlice.actions;

export default dashSlice.reducer;
