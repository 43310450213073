import React, { useLayoutEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import styles from './Oboarding.module.scss';
import ButtonWrapper from '../../../../components/ButtonWrapper/ButtonWrapper';
import Button from '../../../../components/Button/Button';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const content = {
  0: 'Calculate your biological age based on your lifestyle',
  1: 'Improve your habits following a personalized plan',
  2: 'Complete your challenges and obtain rewards',
};

function Onboarding({ onNextStep }) {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    onSwipe: (direction) => {
      if (direction === 'left') {
        return currentScreen < 2 ? setCurrentScreen(currentScreen + 1) : null;
      }

      return currentScreen > 0 ? setCurrentScreen(currentScreen - 1) : null;
    },
  };

  let slider = useRef(null);

  const onNext = () => {
    if (currentScreen === 2) {
      return onNextStep();
    }

    slider.slickGoTo(currentScreen + 1);

    return setCurrentScreen(currentScreen + 1);
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.mainBlock}>
        <div className={styles.skipContainer}>
          {
            currentScreen !== 2 && (
              <div
                className={styles.skipButton}
                onClick={onNextStep}
                onKeyPress={() => {}}
                tabIndex={-1}
                role="button"
              >
                Skip
              </div>
            )
          }
        </div>
        <Slider
          ref={(sl) => {
            slider = sl;
          }}
          {...settings}
        >
          {
            Object.values(content).map((c, idx) => (
              <div className={styles.contentContainer}>
                <div className={styles.image} style={{ height: `${size > 660 ? size - 420 : 240}px` }}>
                  <ReactSVG className={styles.svgImg} src={`/onboarding/onboarding-${idx + 1}.svg`} />
                </div>
                <div className={styles.content}>{c}</div>
              </div>
            ))
          }
        </Slider>
      </div>
      <div className={styles.progress}>
        <ReactSVG src={`/onboarding/progress-${currentScreen + 1}.svg`} />
      </div>
      <ButtonWrapper>
        <Button
          onClick={onNext}
          filled
        >
          {currentScreen === 2 ? 'Let\'s start!' : 'Next'}
        </Button>
      </ButtonWrapper>
    </div>
  );
}

Onboarding.propTypes = {
  onNextStep: PropTypes.func.isRequired,
};

export default Onboarding;
