import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styles from './SelectButtons.module.css';

function SelectButtons({ options = [], onSelect, selected }) {
  const [selectedValue, setValue] = useState(selected);

  return (
    <div className={styles.wrap}>
      {options.map(({ value, name }) => (
        <button
          key={Math.random()}
          type="button"
          onClick={() => {
            setValue(value);
            onSelect(value);
          }}
          className={selectedValue === value ? styles.buttonActive : styles.button}
        >
          {name}
        </button>
      ))}
    </div>
  );
}

SelectButtons.propTypes = {
  options: [{
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }],
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

SelectButtons.defaultProps = {
  options: [],
  selected: null,
};

export default SelectButtons;
