import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import {useDispatch, useSelector} from 'react-redux';
import styles from './SleepScreen.module.scss';
import SelectButtons from '../../../../components/SelectButtons/SelectButtons';
import {saveDataAsync, selectProfile} from '../screenSlice';
import Button from '../../../../components/Button/Button';

const options = {
  eight: 'I get seven or eight hours most nights and wake up feeling refreshed',
  'less-than-six': 'I get less than six hours',
  'without-sleeping-routine': 'I don\'t have a sleeping routine. My sleep’s all over the place.',
};

function SleepScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [selectedValue, setValue] = useState(me.sleep);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <div>
        <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
          <div className={styles.navBarCustomLeft}>
            <Icon color="#30353F" type="left" />
            <div className={styles.navBarCustomText}>Back</div>
          </div>
          <div className={styles.navBarCustomCenter}>10/12</div>
          <div className={styles.navBarCustomRight} />
        </div>
        <div>
          <Progress
            className={styles.progress}
            barStyle={{
              border: '4px solid #9500AD',
              borderRadius: '4px',
            }}
            percent={83}
            position="normal"
          />
        </div>
        <div className={styles.imageWrap}>
          <img className={styles.image} src="/sleep-img.png" alt="Sleep" />
        </div>
        <div className={styles.question}>
          How do you sleep?
        </div>
        <div className={styles.inputWrapper}>
          <SelectButtons
            options={Object.keys(options).map((value) => ({ value, name: options[value] }))}
            onSelect={setValue}
            selected={selectedValue}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ sleep: selectedValue }));
          }}
          disabled={!selectedValue}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

SleepScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  data: PropTypes.shape({ name: PropTypes.string }),
};

SleepScreen.defaultProps = {
  data: { name: 'User' },
};

export default SleepScreen;
