import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DatePicker, Icon, Progress, List,
} from 'antd-mobile';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './BirthScreen.module.scss';
import './DatePicker.scss';
import Input from '../../../../components/Input/Input';
import { saveDataAsync, selectProfile } from '../screenSlice';
import Button from '../../../../components/Button/Button';
import '../../../../components/DatePicker/DatePicker.patch.scss';

function BirthScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [birthDate, setBirthDate] = useState(me?.birth ? new Date(me.birth) : null);
  const dispatch = useDispatch();

  const now = new Date();
  const minYear = now.getFullYear() - 100;
  const maxYear = now.getFullYear() - 18;
  const minDate = new Date(minYear, 0, 1);
  const maxDate = new Date(maxYear, now.getMonth(), now.getDate());

  return (
    <div className={styles.wrap}>
      <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
        <div className={styles.navBarCustomLeft}>
          <Icon color="#30353F" type="left" />
          <div className={styles.navBarCustomText}>Back</div>
        </div>
        <div className={styles.navBarCustomCenter}>4/12</div>
        <div className={styles.navBarCustomRight} />
      </div>
      <div>
        <Progress
          className={styles.progress}
          barStyle={{
            border: '4px solid #9500AD',
            borderRadius: '4px',
          }}
          percent={33.2}
          position="normal"
        />
      </div>
      <div className={styles.logoWrapper}>
        <ReactSVG className={styles.logo} src="/calendar-icon.svg" />
      </div>
      <div className={styles.question}>What is your birth date?</div>
      <div className={styles.inputWrapper}>
        <div className={styles.datePickerWrapper}>
          <DatePicker
            mode="date"
            title="Select date"
            value={birthDate}
            locale={enUs}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(date) => setBirthDate(date)}
          >
            <List.Item arrow="horizontal">Date</List.Item>
          </DatePicker>
        </div>
        <Input
          placeholder="Birth date"
          onChange={() => {}}
          currVal={birthDate?.toLocaleDateString()}
        />
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ birth: birthDate }));
          }}
          disabled={!birthDate}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

BirthScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default BirthScreen;
