import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import styles from './CategoryTitle.module.scss';

function CategoryTitle({ title, buttonText, onClick }) {
  return (
    <div className={styles.titleContainer}>
      <div className={styles.titleContainerTitle}>{title}</div>
      <div
        className={styles.titleContainerButton}
        role="button"
        onClick={onClick}
      >
        {buttonText}
        <ReactSVG className={styles.titleContainerButtonArrow} src="/challenges/arrow-right-main.svg" />
      </div>
    </div>
  );
}

CategoryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

CategoryTitle.defaultProps = {
  buttonText: null,
  onClick: () => null,
};

export default CategoryTitle;
