import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getMeAsync, setToken } from '../auth/authSlice';
import { signIn } from '../auth/authApi';
import statuses from '../../constants/requestStatuses';

export const loginActions = {
  LOGIN: 'auth/login',
};

const initialState = {
  error: null,
};

export const loginAsync = createAsyncThunk(
  loginActions.LOGIN,
  async ({ email, password }, { dispatch }) => {
    const result = await signIn(email, password);

    dispatch(setToken(result.data?.sid));
    dispatch(getMeAsync());

    return result;
  },
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = statuses.LOADING;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.status = statuses.IDLE;
        } else {
          state.status = statuses.ERROR;
          state.error = action.payload?.code;
        }
      });
  },
});

export const selectError = (state) => state.login.error;

export const selectStatus = (state) => state.login.status;

export default loginSlice.reducer;
