import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Scroll.module.scss';
import UpButton from '../UpButton/UpButton';

function Scroll({
  children, adaptive, onScroll, showUpButton,
}) {
  const [currentPosition, setPosition] = useState(0);

  const smoothScrollUp = (target) => {
    const interval = setInterval(() => {
      if (target.scrollTop > 0) {
        target.scrollTop -= target.scrollTop / 50;
      } else {
        clearInterval(interval);
      }
    }, 0.01);
  };

  let content = null;

  return (
    <div className={adaptive ? styles.scrollableWrapAdaptive : styles.scrollableWrap}>
      <div
        className={styles.scrollable}
        onScroll={onScroll || ((e) => setPosition(e.target.scrollTop))}
        ref={(c) => { content = c; }}
      >
        {children}
      </div>
      <div className={styles.upButtonContainer}>
        <UpButton
          onClick={() => {
            smoothScrollUp(content);
          }}
          className={styles.upButton}
          smooth
          percent={showUpButton ? currentPosition * 0.005 : 0}
        />
      </div>
    </div>
  );
}

Scroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  adaptive: PropTypes.bool,
  onScroll: PropTypes.func,
  showUpButton: PropTypes.bool,
};

Scroll.defaultProps = {
  adaptive: false,
  onScroll: null,
  showUpButton: false,
};

export default Scroll;
