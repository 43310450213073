import { apiRequest, apiRequestGet } from '../../helpers/apiRequest';
import methods from '../../constants/httpMethods';

export const getMe = async (token) => apiRequestGet('/users/me', { token, includeDeviceInfo: true });

export const activateAccount = async (activationCode, token) => apiRequest(`/auth/activate/${activationCode}`, methods.POST, {}, { token });

export const forgotPassword = async (email, token) => apiRequest('/auth/reset-password/request', methods.POST, { email }, { token });

export const resetPassword = async (code, password) => apiRequest(
  '/auth/reset-password',
  methods.POST,
  { code, password },
  { includeDeviceInfo: false },
);

export const signIn = async (email, password) => apiRequest('/auth/sign-in', methods.POST, { email, password, rememberMe: true }, { includeDeviceInfo: true });

export const resendActivationMail = async (token) => apiRequest('/auth/activation/resend', methods.POST, {}, { includeDeviceInfo: true, token });

export const logout = async (token) => apiRequest('/auth/logout', methods.POST, {}, { includeDeviceInfo: true, token });

export const changePassword = async (password, newPassword, token) => apiRequest(
  '/auth/change-password',
  methods.POST,
  { oldPassword: password, newPassword },
  { includeDeviceInfo: true, token },
);
