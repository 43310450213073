import React from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './VerifyEmail.module.scss';
import PageBack from '../../components/PageBack/PageBack';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { resendActivationAsync, selectUser } from '../auth/authSlice';

function BamQuestionnaire() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  return (
    <PageBack>
      <PageWrapper>
        <div className={styles.wrap}>
          <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={() => {}} onKeyPress={() => {}}>
            <div className={styles.navBarCustomText} />
          </div>
          <div className={styles.logoWrapper}>
            <ReactSVG className={styles.logo} src="/success/email-logo.svg" />
          </div>
          <div className={styles.title}>Verify your email</div>
          <div className={styles.content}>
            We have sent a verification link to your email
            <b>{` ${user?.email}`}</b>
          </div>
          <div className={styles.haventText}>Haven't receive a verification letter?</div>
          <div
            className={styles.resendButton}
            onClick={() => { dispatch(resendActivationAsync()); }}
            role="button"
          >
            Resend Email
          </div>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default BamQuestionnaire;
