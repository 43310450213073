import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavBar, Icon } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail } from 'validator';
import styles from './ForgotPassword.module.css';
import PageBack from '../../../components/PageBack/PageBack';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import { forgotPasswordAsync, selectForgotPasswordStatus } from '../authSlice';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';

function ForgotPassword() {
  const [email, setEmail] = useState();
  const dispatch = useDispatch();
  const status = useSelector(selectForgotPasswordStatus);

  const history = useHistory();

  let resultMessage = '';
  switch (status) {
    case 'success':
      resultMessage = 'Email sent!';
      break;
    case 'error':
      resultMessage = 'Account not found';
      break;
    case 'loading':
      resultMessage = 'Loading...';
      break;
    default:
      resultMessage = '';
  }

  return (
    <PageBack>
      <PageWrapper>
        <NavBar
          className={styles.navBar}
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => history.push('/login')}
        >
          Forgot Password?
        </NavBar>
        <div className={styles.inputWrapper}>
          <div className={styles.caption}>We just need your Email address to verify your identity</div>
          <div className={styles.input}>
            <Input
              placeholder="Email"
              onChange={(val) => setEmail(val)}
              currVal={email}
              error={email && !isEmail(email) && 'Please enter a valid email address'}
            />
          </div>
          <div className={styles.status}>{resultMessage}</div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={() => dispatch(forgotPasswordAsync({ email: email.toLowerCase() }))}
            filled
            disabled={!email || !isEmail(email)}
          >
            Send
          </Button>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default ForgotPassword;
