import React from 'react';
import PropTypes from 'prop-types';
import CenterDialog from '../CenterDialog/CenterDialog';
import styles from './QuestionModal.module.scss';

function QuestionModal({
  question, description, confirmText, cancelText, onCancel, onConfirm, show, revertOuterAction,
}) {
  return (
    <CenterDialog
      onClose={revertOuterAction ? onConfirm : onCancel}
      show={show}
    >
      <div className={styles.wrap}>
        <div className={styles.question}>{question}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.buttons}>
          <div
            className={styles.cancelButton}
            onClick={onCancel}
            onKeyPress={() => {}}
            role="button"
            tabIndex={-1}
          >
            {cancelText}
          </div>
          <div className={styles.divider} />
          <div
            className={styles.confirmButton}
            onClick={onConfirm}
            onKeyPress={() => {}}
            role="button"
            tabIndex={-1}
          >
            {confirmText}
          </div>
        </div>
      </div>
    </CenterDialog>
  );
}

QuestionModal.propTypes = {
  question: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
  revertOuterAction: PropTypes.bool,
};

QuestionModal.defaultProps = {
  description: '',
  show: false,
  revertOuterAction: false,
};

export default QuestionModal;
