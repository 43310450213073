import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import requestStatuses from '../../../constants/requestStatuses';

export const screenActions = {
  SAVE_DATA: 'bamScreens/saveData',
  GET_PROFILE: 'bamScreens/getProfile',
};

const initialState = {
  error: null,
  profile: null,
  getProfileError: null,
};

export const saveDataAsync = createAsyncThunk(
  screenActions.SAVE_DATA,
  async (data, { getState, dispatch }) => {
    const { auth } = getState();
    try {
      dispatch(setProfileValue(data));
      const response = await axios.patch(
        `${SERVER_URL}/api/v1/user-profiles/my`,
        { ...data, balance: 0 },
        {
          headers: {
            'X-App-Type': 'mobile',
            'X-Session-Id': auth.token,
            'Bypass-Tunnel-Reminder': '*',
          },
        },
      );

      return response.data;
    } catch (e) {
      // log error
      return null;
    }
  },
);

export const getProfileAsync = createAsyncThunk(
  screenActions.GET_PROFILE,
  async (_, { getState }) => {
    const { auth } = getState();
    try {
      const response = await axios.get(
        `${SERVER_URL}/api/v1/user-profiles/my`,
        {
          headers: {
            'X-App-Type': 'mobile',
            'X-Session-Id': auth.token,
            'Bypass-Tunnel-Reminder': '*',
          },
        },
      );

      return response.data;
    } catch (e) {
      // log error
      return null;
    }
  },
);

export const screenSlice = createSlice({
  name: 'bamScreen',
  initialState,
  reducers: {
    setResultStatus: (state) => {
      state.resultStatus = 'waiting';
    },
    setProfileValue: (state, action) => {
      const data = action.payload;
      state.profile = { ...(state.profile || {}), ...data };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveDataAsync.pending, (state) => {
        state.status = requestStatuses.LOADING;
      })
      .addCase(saveDataAsync.fulfilled, (state) => {
        state.status = requestStatuses.IDLE;
      })
      .addCase(getProfileAsync.pending, (state) => {
        state.profileStatus = requestStatuses.LOADING;
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.profileStatus = requestStatuses.IDLE;
        state.profile = action.payload;
      });
  },
});

export const { setResultStatus, setProfileValue } = screenSlice.actions;

export const selectError = (state) => state.bamScreens.error;
export const selectProfile = (state) => state.bamScreens.profile || {};
export const profileWasLoaded = (state) => !!state.bamScreens.profile;
export const selectProfileStatus = (state) => state.bamScreens.profileStatus;
export const selectResultStatus = (state) => state.bamScreens.resultStatus;
export const getChallengesDataTemp = (state) => JSON.parse(state.bamScreens.profile?.selectedLanguage || '{}');

export default screenSlice.reducer;
