import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import {useDispatch, useSelector} from 'react-redux';
import styles from './DrinkScreen.module.scss';
import SelectButtons from '../../../../components/SelectButtons/SelectButtons';
import {saveDataAsync, selectProfile} from '../screenSlice';
import Button from '../../../../components/Button/Button';

const options = {
  'some-times': 'I drink between 1 and 3 days per week, usually beer or wine',
  'every-day': 'I drink every day and I have at least 2 drinks',
  'dont-drink': 'I don\'t drink alcohol',
};

function DrinkScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [selectedValue, setValue] = useState(me?.alcohol);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <div>
        <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
          <div className={styles.navBarCustomLeft}>
            <Icon color="#30353F" type="left" />
            <div className={styles.navBarCustomText}>Back</div>
          </div>
          <div className={styles.navBarCustomCenter}>11/12</div>
          <div className={styles.navBarCustomRight} />
        </div>
        <div>
          <Progress
            className={styles.progress}
            barStyle={{
              border: '4px solid #9500AD',
              borderRadius: '4px',
            }}
            percent={91.3}
            position="normal"
          />
        </div>
        <div className={styles.imageWrap}>
          <img className={styles.image} src="/drink-img.png" alt="Drink" />
        </div>
        <div className={styles.question}>
          How much alcohol do you drink?
        </div>
        <div className={styles.inputWrapper}>
          <SelectButtons
            options={Object.keys(options).map((value) => ({ value, name: options[value] }))}
            onSelect={setValue}
            selected={selectedValue}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ alcohol: selectedValue }));
          }}
          disabled={!selectedValue}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

DrinkScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  data: PropTypes.shape({ name: PropTypes.string }),
};

DrinkScreen.defaultProps = {
  data: { name: 'User' },
};

export default DrinkScreen;
