import React from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import styles from './Menu.module.scss';
import routes from '../../constants/routes';

const checkPath = (path, route) => path === route || path === `${route}/`;

const getTextClass = (path, check) => (
  checkPath(path, check) ? styles.bottomMenuItemTextActive : styles.bottomMenuItemText
);

function Menu() {
  const history = useHistory();

  const path = history.location?.pathname;

  return (
    <div className={styles.bottomMenu}>
      <div
        className={styles.bottomMenuItem}
        onClick={() => history.push(routes.MAIN)}
        role="button"
        tabIndex={-1}
        onKeyPress={() => {}}
      >
        <div><ReactSVG src={checkPath(path, routes.MAIN) ? '/home-button-icon-active.svg' : '/home-button-icon.svg'} /></div>
        <div className={getTextClass(path, routes.MAIN)}>Home</div>
      </div>
      <div
        className={styles.bottomMenuItem}
        onClick={() => history.push(routes.LEARN)}
        role="button"
        tabIndex={-1}
        onKeyPress={() => {}}
      >
        <div><ReactSVG src={checkPath(path, routes.LEARN) ? '/book-button-icon-active.svg' : '/book-button-icon.svg'} /></div>
        <div className={getTextClass(path, routes.LEARN)}>Learn</div>
      </div>
      <div
        className={styles.bottomMenuItem}
        onClick={() => history.push(routes.REWARDS)}
        role="button"
        tabIndex={-1}
        onKeyPress={() => {}}
      >
        <div><ReactSVG src={checkPath(path, routes.REWARDS) ? '/menu/reward-icon-active.svg' : '/menu/reward-icon.svg'} /></div>
        <div className={getTextClass(path, routes.REWARDS)}>
          Reward
        </div>
      </div>
      <div
        className={styles.bottomMenuItem}
        onClick={() => history.push(routes.PROFILE)}
        role="button"
        tabIndex={-1}
        onKeyPress={() => {}}
      >
        <div><ReactSVG src={checkPath(path, routes.PROFILE) ? '/person-button-icon-active.svg' : '/person-button-icon.svg'} /></div>
        <div className={getTextClass(path, routes.PROFILE)}>
          Profile
        </div>
      </div>
    </div>
  );
}

export default Menu;
