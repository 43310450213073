import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import styles from './UpButton.module.scss';

function UpButton({
  onClick, className, display, percent, smooth,
}) {
  return (
    <div
      style={{ ...(smooth ? { opacity: percent } : {}) }}
      className={`${styles.upButton} ${className} ${display || smooth ? '' : styles.upButtonHidden}`}
      onClick={onClick}
      role="button"
    >
      <ReactSVG src="/navigation/up-yellow-arrow.svg" />
    </div>
  );
}

UpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  display: PropTypes.bool,
  percent: PropTypes.number,
  smooth: PropTypes.bool,
};

UpButton.defaultProps = {
  className: '',
  display: false,
  percent: 0,
  smooth: false,
};

export default UpButton;
