import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getMe, activateAccount, forgotPassword, resetPassword, resendActivationMail, logout, changePassword,
} from './authApi';
import asyncReducersBuilder from '../../helpers/asyncReducersBuilder';
import asyncRequestWithStatus from '../../helpers/asyncRequestWithStatus';
import { clearStorage } from '../dashboard/dashboardSlice';

export const authActions = {
  AUTHENTICATE: 'auth/authenticate',
  AUTHORIZATION: 'auth/authorization',
  ACTIVATE: 'auth/activate',
  FORGOT_PASSWORD: 'auth/forgotPassword',
  RESET_PASSWORD: 'auth/resetPassword',
  RESEND_ACTIVATION: 'auth/resendActivation',
  LOG_OUT: 'auth/logOut',
  CHANGE_PASSWORD: 'auth/changePassword',
};

const initialState = {
  user: null,
  status: 'idle',
  token: localStorage.getItem('auth-token'),
  resetPasswordStatus: null,
  resendActivationStatus: null,
  statusGetMe: null,
  logOutStatus: null,
  changePasswordStatus: null,
  activationToken: null,
};

export const getMeAsync = createAsyncThunk(
  authActions.AUTHENTICATE,
  async (_, { getState }) => {
    const { auth } = getState();

    return getMe(auth.token);
  },
);

export const activateAsync = createAsyncThunk(
  authActions.ACTIVATE,
  async ({ code }, { getState, dispatch }) => {
    const { auth, activationStatus } = getState();

    if (activationStatus !== 'loading') {
      const result = await activateAccount(code, auth.token);
      dispatch(getMeAsync());

      return result;
    }

    return null;
  },
);

export const forgotPasswordAsync = createAsyncThunk(
  authActions.FORGOT_PASSWORD,
  async ({ email }, { getState }) => {
    const { auth, forgotPasswordStatus } = getState();

    if (forgotPasswordStatus !== 'loading') {
      return forgotPassword(email, auth.token);
    }

    return null;
  },
);

export const resetPasswordAsync = createAsyncThunk(
  authActions.RESET_PASSWORD,
  async ({ code, password }, { getState }) => {
    const { resetPasswordStatus } = getState();

    if (resetPasswordStatus !== 'loading') {
      return resetPassword(code, password);
    }

    return null;
  },
);

export const resendActivationAsync = createAsyncThunk(
  authActions.RESEND_ACTIVATION,
  async (_, { getState }) => {
    const { resendActivationStatus, auth } = getState();

    if (resendActivationStatus !== 'loading') {
      return resendActivationMail(auth.token);
    }

    return null;
  },
);

export const logOutAsync = createAsyncThunk(
  authActions.LOG_OUT,
  async (_, { getState, dispatch }) => {
    const { logOutStatus, auth } = getState();

    return asyncRequestWithStatus(logOutStatus, async () => {
      const result = await logout(auth.token);
      dispatch(clearToken());
      dispatch(clearUser());
      dispatch(clearStorage());

      return result;
    });
  },
);

export const changePasswordAsync = createAsyncThunk(
  authActions.CHANGE_PASSWORD,
  async ({ password, newPassword }, { getState }) => {
    const { changePasswordStatus, auth } = getState();

    return asyncRequestWithStatus(changePasswordStatus, async () => changePassword(password, newPassword, auth.token));
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('auth-token', action.payload);
    },
    setTranslations: (state, action) => {
      state.translations = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
      localStorage.removeItem('auth-token');
    },
    resetStatus: (state, action) => {
      const funcName = action.payload.name;
      if (state[`${funcName}Status`]) {
        state[`${funcName}Status`] = null;
      }
    },
    setActivationToken: (state, action) => {
      state.activationToken = action.payload.token;
    },
    clearActivationToken: (state) => {
      state.activationToken = null;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeAsync.pending, (state) => {
        state.statusGetMe = 'loading';
      })
      .addCase(getMeAsync.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        if (success) {
          state.statusGetMe = 'idle';
          state.user = data;
        } else {
          state.statusGetMe = 'error';
          state.user = null;
        }
      })
      .addCase(activateAsync.pending, (state) => {
        state.activationStatus = 'loading';
      })
      .addCase(activateAsync.fulfilled, (state, action) => {
        const { success } = action.payload;
        state.activationStatus = success ? 'success' : 'error';
      })
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.forgotPasswordStatus = 'loading';
      })
      .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
        const { success } = action.payload;
        state.forgotPasswordStatus = success ? 'success' : 'error';
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.resetPasswordStatus = 'loading';
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        const { success } = action.payload;
        state.resetPasswordStatus = success ? 'success' : 'error';
      });
    asyncReducersBuilder(builder, logOutAsync, 'logOutStatus');
    asyncReducersBuilder(builder, changePasswordAsync, 'changePasswordStatus');
  },
});

export const {
  setToken, setTranslations, clearToken, resetStatus, setActivationToken, clearActivationToken, clearUser,
} = authSlice.actions;

export const userWasLoaded = (state) => state.auth.statusGetMe === 'idle';
export const selectGetMeStatus = (state) => state.auth.statusGetMe;
export const selectUser = (state) => state.auth.user;
export const selectActivationStatus = (state) => state.auth.activationStatus;
export const selectForgotPasswordStatus = (state) => state.auth.forgotPasswordStatus;
export const selectChangePasswordStatus = (state) => state.auth.changePasswordStatus;
export const selectActivationToken = (state) => state.auth.activationToken;

export default authSlice.reducer;
