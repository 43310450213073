import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderNav from '../../../components/HeaderNav/HeaderNav';
import styles from './ClaimedRewards.module.scss';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';
import PageBack from '../../../components/PageBack/PageBack';
import RewardBlock from '../../../components/RewardBlock/RewardBlock';
import RewardDetailsModal from '../../../components/RewardDetailsModal/RewardDetailsModal';
import { getChallengesDataTemp } from '../../bamQuestionnaire/screens/screenSlice';
import Scroll from '../../../components/Scroll/Scroll';

const rewards = [
  {
    id: 1,
    image: '/rewards/images/30-minute-horseback.jpeg',
    title: '30-Minute Private Horseback Riding Lesson',
    totalPoints: 10,
    points: 5,
    description: 'Horseback riding is a fine activity that allows individuals to learn this fine art. While it is no longer the necessary mode of transportation, it still allows fans of the mode to understand how to interact with the majestic beast, handle it',
  },
  {
    id: 2,
    image: '/rewards/images/one-month-gym.jpeg',
    title: 'One-Month Gym Membership for One',
    totalPoints: 10,
    points: 10,
    description: 'Enjoy our constantly varied workouts led by experienced coaches in a clean, welcoming space. Beginner through advanced levels.',
  },
  {
    id: 3,
    image: '/rewards/images/yoga-class.jpeg',
    title: 'Yoga Class 40% voucher',
    totalPoints: 10,
    points: 20,
    description: 'Yoga classes blend an emphasis on the physical and spiritual with a deep relaxation in a studio that’s inspired students over 50 years',
  },
  {
    id: 4,
    image: '/rewards/images/meditation.jpeg',
    title: 'Meditation, Yoga Teaching or Hatha Yoga Online Course',
    totalPoints: 10,
    points: 30,
    description: 'Emotional Freedom Technique will teach to tap on acupressure points while saying affirmations to calm stress and anxiety while reducing pain',
  },
  {
    id: 5,
    image: '/rewards/images/healthcheck.jpeg',
    title: 'Lifestyle Health Check via Blood Testing at I-Screen, Choice of Locations',
    totalPoints: 100,
    points: 25,
    description: 'Composition analysis tells clients with 98% accuracy their body fat percentage; the amount of fat & muscle they have, and where it is stored',
  },
];

function ClaimedRewards() {
  const history = useHistory();

  const [currentReward, setCurrentReward] = useState(rewards[0]);
  const [showDetails, setShowDetails] = useState(false);

  const { totalPoints = 30, points = 0, claimedRewardsIds = [] } = useSelector(getChallengesDataTemp);

  const filteredRewards = rewards.filter((r) => claimedRewardsIds.indexOf(r.id) !== -1);

  const openModal = (reward) => () => {
    setShowDetails(true);
    setCurrentReward(reward);
  };

  return (
    <>
      <RewardDetailsModal
        title={currentReward.title}
        display={showDetails}
        onClick={() => {
          setShowDetails(false);
        }}
        onClose={() => setShowDetails(false)}
        imageUrl={currentReward.image}
        points={currentReward.points}
        pointsDiff={points - currentReward.points}
        totalPointsDiff={totalPoints - currentReward.totalPoints}
        totalPoints={currentReward.totalPoints}
        description={currentReward.description}
        claimed
      />
      <PageBack>
        <PageWrapper>
          <HeaderNav
            onBack={() => history.goBack()}
            title={`Rewards claimed (${filteredRewards.length})`}
          />
          <Scroll>
            {!!filteredRewards.length && (
              <div className={styles.date}>
                {new Date().toLocaleDateString()}
              </div>
            )}
            <div className={styles.rewardsContainer}>
              {filteredRewards.map((r) => (
                <RewardBlock
                  key={Math.random()}
                  pointsRequired={r.points}
                  title={r.title}
                  onClick={openModal(r)}
                  imageUrl={r.image}
                  totalPointsRequired={r.totalPoints}
                  claimed
                />
              ))}
            </div>
          </Scroll>
        </PageWrapper>
      </PageBack>
    </>
  );
}

export default ClaimedRewards;
