import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './App.module.css';
import Welcome from './features/welcome/Welcome';
import Registration from './features/registration/Registration';
import Login from './features/login/Login';
import ForgotPassword from './features/auth/forgotPassword/ForgotPassword';
import ResetPassword from './features/resetPassword/ResetPassword';
import BamQuestionnaire from './features/bamQuestionnaire/BamQuestionnaire';
import { getMeAsync, selectGetMeStatus } from './features/auth/authSlice';
import Activate from './features/activate/Activate';
import { VERSION } from './config';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import Dashboard from './features/dashboard/Dashboard';
import routes from './constants/routes';
import { getProfileAsync } from './features/bamQuestionnaire/screens/screenSlice';
import Learn from './features/learn/Learn';
import Article from './features/learn/article/Article';
import ChallengeFinish from './components/ChallengeFinishScreen/ChallengeFinishScreen';
import Rewards from './features/rewards/Rewards';
import Profile from './features/profile/Profile';
import EditProfile from './features/editProfile/EditProfile';
import ChangePassword from './features/auth/changePassword/ChangePassword';
import requestStatuses from './constants/requestStatuses';
import CompletedChallenges from './features/learn/completedChallenges/CompletedChallenges';
import ClaimedRewards from './features/rewards/claimedRewards/ClaimedRewards';

function App() {
  console.log(`Version: ${VERSION}`);

  const dispatch = useDispatch();
  const status = useSelector(selectGetMeStatus);

  if (!status) {
    dispatch(getMeAsync());

    return <div />;
  }
  if (status === requestStatuses.IDLE) {
    dispatch(getProfileAsync());
  }

  return (
    <div className={styles.AppWrap}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Switch>
            <PublicRoute path={routes.WELCOME}><Welcome /></PublicRoute>
            <PublicRoute path={routes.REGISTRATION}><Registration /></PublicRoute>
            <PublicRoute path={routes.LOGIN} skipVerify><Login /></PublicRoute>
            <PublicRoute path={routes.FORGOT_PASSWORD}><ForgotPassword /></PublicRoute>
            <PublicRoute path={routes.RESET_PASSWORD} skipVerify><ResetPassword /></PublicRoute>
            <PublicRoute path={routes.ACTIVATE_USER} skipVerify><Activate /></PublicRoute>

            {/* <Route path={routes.VERIFY_EMAIL}><VerifyEmail /></Route> */}

            <PrivateRoute path={routes.BAM_QUEST}><BamQuestionnaire /></PrivateRoute>
            <PrivateRoute path={routes.MAIN} exact><Dashboard /></PrivateRoute>
            <PrivateRoute path={routes.LEARN}><Learn /></PrivateRoute>
            <PrivateRoute path={routes.CHALLENGES_COMPLETED}><CompletedChallenges /></PrivateRoute>
            <PrivateRoute path={routes.ARTICLE}><Article /></PrivateRoute>
            <PrivateRoute path={routes.SUCCESS_CHALLENGE}><ChallengeFinish /></PrivateRoute>
            <PrivateRoute path={routes.REWARDS} exact><Rewards /></PrivateRoute>
            <PrivateRoute path={routes.REWARDS_CLAIMED} exact><ClaimedRewards /></PrivateRoute>
            <PrivateRoute path={routes.PROFILE} exact><Profile /></PrivateRoute>
            <PrivateRoute path={routes.EDIT_PROFILE}><EditProfile /></PrivateRoute>
            <PrivateRoute path={routes.CHANGE_PASSWORD}><ChangePassword /></PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
