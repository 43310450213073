import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './GenderScreen.module.scss';
import SelectButtons from '../../../../components/SelectButtons/SelectButtons';
import { saveDataAsync, selectProfile } from '../screenSlice';
import Button from '../../../../components/Button/Button';

const genderOptions = {
  female: 'Female',
  male: 'Male',
  'non-binary': 'Non-binary',
  transgender: 'Transgender',
  intersex: 'Intersex',
  none: 'I prefer not to say',
};

function GenderScreen({ onNextStep, onBack, data }) {
  const me = useSelector(selectProfile);

  const [selectedValue, setGender] = useState(me?.gender);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <div>
        <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
          <div className={styles.navBarCustomLeft}>
            <Icon color="#30353F" type="left" />
            <div className={styles.navBarCustomText}>Back</div>
          </div>
          <div className={styles.navBarCustomCenter}>2/12</div>
          <div className={styles.navBarCustomRight} />
        </div>
        <div>
          <Progress
            className={styles.progress}
            barStyle={{
              border: '4px solid #9500AD',
              borderRadius: '4px',
            }}
            percent={16.6}
            position="normal"
          />
        </div>
        <div className={styles.logoWrapper}>
          <ReactSVG className={styles.logo} src="/gender-icon.svg" />
        </div>
        <div className={styles.question}>
          Great
          {` ${data.name}`}
          , to which gender do you identify yourself?
        </div>
        <div className={styles.description}>
          This will help us understand how to address you
        </div>
        <div className={styles.inputWrapper}>
          <SelectButtons
            options={Object.keys(genderOptions).map((value) => ({ value, name: genderOptions[value] }))}
            onSelect={setGender}
            selected={selectedValue}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ gender: selectedValue }));
          }}
          disabled={!selectedValue}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

GenderScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  data: PropTypes.shape({ name: PropTypes.string }),
};

GenderScreen.defaultProps = {
  data: { name: 'User' },
};

export default GenderScreen;
