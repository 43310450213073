import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import styles from './RewardDetailsModal.module.scss';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import ButtonWrapper from '../ButtonWrapper/ButtonWrapper';
import Scroll from '../Scroll/Scroll';

function RewardDetailsModal({
  title, onClose, onClick, display, imageUrl, points, pointsDiff, totalPointsDiff, totalPoints, claimed, description,
}) {
  const blocked = !claimed && totalPointsDiff < 0;
  const needMore = !claimed && !blocked && pointsDiff < 0;

  return (
    <Modal display={display} onClose={onClose} height={80}>
      <Scroll>
        <div className={styles.wrap}>
          <div className={styles.moverContainer} onClick={onClose} role="button">
            <div className={styles.mover} />
          </div>
          <div className={styles.contentWrapper}>
            <img className={`${styles.image} ${needMore || blocked ? styles.imageBlocked : ''}`} src={imageUrl} alt="reward" />
            <div className={styles.title}>{title}</div>
            <div className={`${styles.pointsInfo} ${needMore || blocked ? styles.pointsInfoBlocked : ''} ${claimed ? styles.pointsInfoClaimed : ''}`}>
              <div className={styles.pointsInfoTitle}>
                {blocked && <ReactSVG className={styles.lockIcon} src="/rewards/lock-black-big-icon.svg" />}
                {blocked && (
                  <div>
                    <p>Blocked.</p>
                    <p>{`Win ${totalPoints} total points to claim`}</p>
                  </div>
                )}
                {needMore && `Need ${Math.abs(pointsDiff)} points to claim`}
                {!needMore && !blocked && !claimed && 'You will spend'}
                {claimed && <ReactSVG className={styles.successIcon} src="/rewards/success-black-icon.svg" />}
                {claimed && 'Claimed'}
              </div>
              {!needMore && !blocked && !claimed && (
                <div className={styles.pointsInfoPoints}>
                  <ReactSVG className={styles.pointsInfoPointsIcon} src="/rewards/star-orange-big-icon.svg" />
                  {`${points} points`}
                </div>
              )}
              {claimed && (
                <div className={`${styles.pointsInfoPoints} ${styles.pointsInfoPointsGreen}`}>
                  <ReactSVG className={styles.pointsInfoPointsIcon} src="/rewards/star-green-icon.svg" />
                  {`You used ${points} points`}
                </div>
              )}
            </div>
            <div className={styles.text}>
              {description}
            </div>
          </div>
          {
            !needMore && !blocked && !claimed && (
              <ButtonWrapper>
                <Button onClick={onClick} filled>Claim reward</Button>
              </ButtonWrapper>
            )
          }
          {
            (needMore || blocked) && (
              <ButtonWrapper>
                <Button onClick={onClose}>Close</Button>
              </ButtonWrapper>
            )
          }
          {
            claimed && (
              <div>
                <div className={styles.line} />
                <div className={styles.issueText}>
                  Did you have issues with
                  the reward or you didn't receive it?
                </div>
                <ButtonWrapper>
                  <Button onClick={onClose}>Contact Us</Button>
                </ButtonWrapper>
              </div>
            )
          }
        </div>
      </Scroll>
    </Modal>
  );
}

RewardDetailsModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  pointsDiff: PropTypes.number.isRequired,
  totalPointsDiff: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  totalPoints: PropTypes.number.isRequired,
  claimed: PropTypes.bool,
  description: PropTypes.string.isRequired,
};

RewardDetailsModal.defaultProps = {
  claimed: false,
};

export default RewardDetailsModal;
