import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getMeAsync, setToken } from '../auth/authSlice';
import { SERVER_URL } from '../../config';

export const regActions = {
  REGISTRATION: 'auth/registration',
};

// TODO: refactor this all
const initialState = {
  error: null,
};

export const registrationAsync = createAsyncThunk(
  regActions.REGISTRATION,
  async ({ email, password }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/api/v1/auth/sign-up`, // TODO: fix this
        {
          email, password, rememberMe: true, deviceInfo: 'brrr', deviceId: '123',
        },
        {
          headers: {
            'X-App-Type': 'mobile',
            'Bypass-Tunnel-Reminder': '*',
          },
        },
      );

      dispatch(setToken(response.data?.sid));
      dispatch(getMeAsync());

      return response.data;
    } catch (e) {
      dispatch(setError({ code: e.response?.status }));

      return null;
    }
  },
);

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload.code;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registrationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(registrationAsync.fulfilled, (state) => {
        state.status = 'idle';
      });
  },
});

export const { setError } = registrationSlice.actions;

// export const selectUser = (state) => state.user;
export const selectError = (state) => state.registration.error;

export default registrationSlice.reducer;
