import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import loginReducer from '../features/login/loginSlice';
import registrationReducer from '../features/registration/registrationSlice';
import bamScreensReducer from '../features/bamQuestionnaire/screens/screenSlice';
import learnReducer from '../features/learn/learnSlice';
import dashReducer from '../features/dashboard/dashboardSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    login: loginReducer,
    registration: registrationReducer,
    bamScreens: bamScreensReducer,
    learn: learnReducer,
    dash: dashReducer,
  },
});

export default store;
