import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd-mobile';
import styles from './Button.module.scss';

function ButtonMain({
  filled,
  onClick,
  disabled,
  children,
  transparent,
}) {
  return (
    <Button
      type="button"
      className={[
        styles.button,
        (filled ? styles.buttonFilled : styles.buttonEmpty),
        ...(transparent ? [styles.buttonTransparent] : []),
      ]}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

ButtonMain.propTypes = {
  filled: PropTypes.bool,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
};

ButtonMain.defaultProps = {
  filled: false,
  disabled: false,
  transparent: false,
};

export default ButtonMain;
