import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import {
  activateAsync, clearActivationToken, selectActivationStatus, selectGetMeStatus, selectUser, setActivationToken,
} from '../auth/authSlice';
import requestStatuses from '../../constants/requestStatuses';
import routes from '../../constants/routes';

function Activate() {
  const status = useSelector(selectActivationStatus);
  const { code } = useParams();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const uStatus = useSelector(selectGetMeStatus);
  const loggedIn = !!user?.userId;

  if (!status && (uStatus === requestStatuses.IDLE && loggedIn)) {
    dispatch(clearActivationToken());
    dispatch(activateAsync({ code }));
  }

  if ([requestStatuses.IDLE, requestStatuses.ERROR].indexOf(uStatus) !== -1 && !loggedIn) {
    dispatch(setActivationToken({ token: code }));

    return <Redirect to={routes.LOGIN} />;
  }

  return <div />;
}

export default Activate;
