import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getArticle, getArticles, getChallenges, getCompletedChallenges, postChallengeSubmit,
} from './learnApi';
import callWithStatus from '../../helpers/callWithStatus';
import asyncReducersBuilder from '../../helpers/asyncReducersBuilder';

export const learnActions = {
  GET_ARTICLES: 'learn/getArticles',
  GET_CHALLENGES: 'learn/getChallenges',
  GET_CHALLENGES_FINISHED: 'lean/getChallengesFinished',
  POST_SUBMIT_FORM: 'learn/postSubmitForm',
  GET_ARTICLE: 'learn/article',
};

const initialState = {
  articles: [],
  articlesStatus: null,
  challenges: [],
  completedChallenges: [],
  completedChallengesStatus: null,
  challengesStatus: null,
  submitFormStatus: null,
  article: null,
  articleStatus: null,
};

export const getArticlesAsync = createAsyncThunk(
  learnActions.GET_ARTICLES,
  async (_, { getState }) => {
    const { auth, articlesStatus } = getState();

    return callWithStatus(() => getArticles(auth.token), articlesStatus);
  },
);

export const getChallengesAsync = createAsyncThunk(
  learnActions.GET_CHALLENGES,
  async (_, { getState }) => {
    const { auth, challengesStatus } = getState();

    return callWithStatus(() => getChallenges(auth.token), challengesStatus);
  },
);

export const getCompletedChallengesAsync = createAsyncThunk(
  learnActions.GET_CHALLENGES_FINISHED,
  async (_, { getState }) => {
    const { auth, completedChallengesStatus } = getState();

    return callWithStatus(() => getCompletedChallenges(auth.token), completedChallengesStatus);
  },
);

export const postChallengeSubmitAsync = createAsyncThunk(
  learnActions.POST_SUBMIT_FORM,
  async ({ challengeId, answerId }, { getState }) => {
    const { auth, submitFormStatus } = getState();

    return callWithStatus(() => postChallengeSubmit(challengeId, answerId, auth.token), submitFormStatus);
  },
);

export const getSingleArticleAsync = createAsyncThunk(
  learnActions.GET_ARTICLE,
  async ({ articleId }, { getState }) => {
    const { auth, articleStatus } = getState();

    return callWithStatus(() => getArticle(articleId, auth.token), articleStatus);
  },
);

export const learnSlice = createSlice({
  name: 'learn',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    asyncReducersBuilder(builder, getArticlesAsync, 'articlesStatus', 'articles');
    asyncReducersBuilder(builder, getChallengesAsync, 'challengesStatus', 'challenges');
    asyncReducersBuilder(builder, postChallengeSubmitAsync, 'submitFormStatus');
    asyncReducersBuilder(builder, getSingleArticleAsync, 'articleStatus', 'article');
    asyncReducersBuilder(builder, getCompletedChallengesAsync, 'completedChallengesStatus', 'completedChallenges');
  },
});

export const selectArticlesData = (state) => {
  const { articles, articlesStatus } = state.learn;

  return { articles, status: articlesStatus };
};

export const selectChallengesData = (state) => {
  const { challenges, challengesStatus } = state.learn;

  return { challenges, status: challengesStatus };
};

export const selectCompletedChallengesData = (state) => {
  const { completedChallenges, completedChallengesStatus } = state.learn;

  return { completedChallenges, status: completedChallengesStatus };
};

export const selectSubmitFormStatus = (state) => state.learn.submitFormStatus;

export const selectArticleData = (state) => {
  const { article, articleStatus } = state.learn;

  return { article, status: articleStatus };
};

export default learnSlice.reducer;
