import React from 'react';
import PropTypes from 'prop-types';
import styles from './ButtonWrapper.module.scss';

function ButtonWrapper({ children }) {
  return (
    <div className={styles.buttonWrapper}>{children}</div>
  );
}

ButtonWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default ButtonWrapper;
