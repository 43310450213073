export default {
  REGISTRATION: '/registration',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password/:code',
  ACTIVATE_USER: '/auth/activate/:code',
  VERIFY_EMAIL: '/verify-email',
  BAM_QUEST: '/start',
  MAIN: '/',
  LEARN: '/learn',
  CHALLENGES_COMPLETED: '/challenges/finished',
  ARTICLE: '/article/:articleId',
  SUCCESS_CHALLENGE: '/success',
  REWARDS: '/rewards',
  REWARDS_CLAIMED: '/rewards/claimed',
  PROFILE: '/profile',
  WELCOME: '/welcome',
  EDIT_PROFILE: '/profile/edit',
  CHANGE_PASSWORD: '/change-password',
};
