import React from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import styles from './ChallengeFinishScreen.module.scss';
import Button from '../Button/Button';
import PageBack from '../PageBack/PageBack';
import PageWrapper from '../PageWrapper/PageWrapper';

function ChallengeFinish() {
  const history = useHistory();

  return (
    <PageBack>
      <PageWrapper>
        <div className={styles.wrap}>
          <div className={styles.contentWrapper}>
            <div className={styles.logoWrapper}>
              <ReactSVG className={styles.logo} src="/challenges/success-icon.svg" />
            </div>
            <div className={styles.title}>Success!</div>
            <div className={styles.content}>
              You have successfully completed the challenge and received
              {` ${15} `}
              points.
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button onClick={() => history.push('/')}>Back to home</Button>
          </div>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default ChallengeFinish;
