import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import styles from './HeaderNav.module.scss';

function HeaderNav({ title, onBack, white }) {
  return (
    <div className={styles.wrap}>
      <div
        className={styles.leftButton}
        onClick={onBack}
        onKeyPress={onBack}
        role="button"
        tabIndex={-1}
      >
        <ReactSVG src={white ? '/header/left-white-icon.svg' : '/header/left-icon.svg'} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.rightButton} />
    </div>
  );
}

HeaderNav.propTypes = {
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  white: PropTypes.bool,
};

HeaderNav.defaultProps = {
  white: false,
};

export default HeaderNav;
