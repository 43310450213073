import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import styles from './EatScreen.module.scss';
import SelectButtons from '../../../../components/SelectButtons/SelectButtons';
import { saveDataAsync, selectProfile } from '../screenSlice';
import Button from '../../../../components/Button/Button';

const options = {
  balanced: 'I eat balanced. My diet mainly includes fruits and vegetables, fish and occasionally meat',
  'some-junk-food': 'I try to follow a balanced diet, but sometimes I have junk food',
  junk: 'My diet’s not all it could be. I have a sweet tooth and live on convenience meals',
};

function EatScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [selectedValue, setValue] = useState(me?.diet);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <div className={styles.contentWrapper}>
        <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
          <div className={styles.navBarCustomLeft}>
            <Icon color="#30353F" type="left" />
            <div className={styles.navBarCustomText}>Back</div>
          </div>
          <div className={styles.navBarCustomCenter}>9/12</div>
          <div className={styles.navBarCustomRight} />
        </div>
        <div>
          <Progress
            className={styles.progress}
            barStyle={{
              border: '4px solid #9500AD',
              borderRadius: '4px',
            }}
            percent={74.7}
            position="normal"
          />
        </div>
        <div className={styles.imageWrap}>
          <img className={styles.image} src="/eat-img.png" alt="Diet" />
        </div>
        <div className={styles.question}>
          How do you eat?
        </div>
        <div className={styles.inputWrapper}>
          <SelectButtons
            options={Object.keys(options).map((value) => ({ value, name: options[value] }))}
            onSelect={setValue}
            selected={selectedValue}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ diet: selectedValue }));
          }}
          disabled={!selectedValue}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

EatScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  data: PropTypes.shape({ name: PropTypes.string }),
};

EatScreen.defaultProps = {
  data: { name: 'User' },
};

export default EatScreen;
