import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import { DatePicker, List } from 'antd-mobile';
import enUs from 'antd-mobile/lib/date-picker/locale/en_US';
import Croppie from 'croppie';
import PageBack from '../../components/PageBack/PageBack';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import HeaderNav from '../../components/HeaderNav/HeaderNav';
import Input from '../../components/Input/Input';
import { saveDataAsync, selectProfile } from '../bamQuestionnaire/screens/screenSlice';
import styles from './EditProfile.module.scss';
import SelectModal from '../../components/SelectModal/SelectModal';
import routes from '../../constants/routes';
import Button from '../../components/Button/Button';
import QuestionModal from '../../components/QuestionModal/QuestionModal';
import '../../components/DatePicker/DatePicker.patch.scss';
import './croppie.css';
import Scroll from '../../components/Scroll/Scroll';
import SuccessScreenGreen from '../../components/SuccessScreenGreen/SuccessScreenGreen';

const croppieOptions = {
  enableOrientation: true,
  mouseWheelZoom: true,
  viewport: {
    width: '',
    type: 'circle',
  },
  boundary: {
    width: '100%',
    height: '100%',
  },
};

const workOptions = {
  'self-employed': 'Self-employed',
  'full-time': 'Employee-full time',
  'part-time': 'Employee - part time',
  'dont-work': 'I don\'t work',
  homemaker: 'Full-time homemaker',
  retired: 'I am retired',
};

const employeeOptions = Object.keys(workOptions).reduce((acc, key) => {
  acc.push({ value: workOptions[key], key });

  return acc;
}, []);

function EditProfile() {
  const me = useSelector(selectProfile);
  const history = useHistory();
  const dispatch = useDispatch();

  const [fullName, setFullName] = useState(null);
  const [birth, setBirth] = useState();
  const [weight, setWeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [employment, setEmployment] = useState();

  const [showEmpModal, setShowEmpModal] = useState(false);
  const [showSaveAnswer, setShowSaveAnswer] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const now = new Date();
  const minYear = now.getFullYear() - 100;
  const maxYear = now.getFullYear() - 18;
  const minDate = new Date(minYear, 0, 1);
  const maxDate = new Date(maxYear, now.getMonth(), now.getDate());

  // load photo
  const [croppie, setCroppie] = useState(null);
  const [croppedImg, setCroppedImg] = useState('');
  const [showCropper, setShowCropper] = useState(false);

  const onFileUpload = (e) => {
    const { files } = e.target;
    const reader = new FileReader();
    const file = files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      let c = null;
      if (!croppie) {
        c = new Croppie(document.getElementsByClassName('croppie')[0], croppieOptions);
      } else {
        c = croppie;
      }
      c.bind({ url: reader.result });
      setCroppie(c);
      setShowCropper(true);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onResult = () => {
    croppie.result('base64').then((base64) => {
      setCroppedImg(base64);
    });
  };

  return (
    <PageBack>
      <SuccessScreenGreen
        buttonText="Ok!"
        onClick={() => {
          setShowSuccess(false);

          return history.push(routes.PROFILE);
        }}
        title="Changes saved"
        description="Your profile has been successfully updated"
        show={showSuccess}
        autoClose
      />
      <PageWrapper>
        <div className={showCropper ? styles.cropperWrap : `${styles.cropperWrap} ${styles.cropperWrapHidden}`}>
          <div className="croppie" />
          <div className={styles.cropButtons}>
            <div
              className={styles.cropButton}
              onClick={() => {
                setShowCropper(false);
              }}
              onKeyPress={() => {}}
              tabIndex={-1}
              role="button"
            >
              Cancel
            </div>
            <div
              className={styles.cropButton}
              onClick={() => {
                onResult();
                setShowCropper(false);
              }}
              onKeyPress={() => {}}
              tabIndex={-1}
              role="button"
            >
              Choose
            </div>
          </div>
        </div>
        <QuestionModal
          confirmText="Continue editing"
          onCancel={() => history.push(routes.PROFILE)}
          question="Are you sure you want to leave?"
          description="You have unsaved changes that will be lost."
          cancelText="Discard changes"
          show={showSaveAnswer}
          onConfirm={() => setShowSaveAnswer(false)}
          revertOuterAction
        />
        <SelectModal
          options={employeeOptions}
          title="Choose your current employment status"
          show={showEmpModal}
          cancelText="Cancel"
          onCancel={() => setShowEmpModal(false)}
          onSelect={(key) => setEmployment(key)}
          selected={employment}
        />
        <HeaderNav
          title="Edit Profile"
          onBack={() => {
            if (fullName || birth || weight || height || employment) {
              return setShowSaveAnswer(true);
            }

            return history.push(routes.PROFILE);
          }}
        />
        <Scroll>
          <div className={styles.contentContainer}>
            <div>
              <div className={styles.imageContainer}>
                <div className={styles.image}>
                  <img src={croppedImg || '/temp/avatar1.jpeg'} alt="user" />
                </div>
              </div>
              <div
                className={styles.editImageButton}
              >
                Edit Image
                <input
                  type="file"
                  id="files"
                  accept="image/*"
                  onChange={onFileUpload}
                />
              </div>
              <Input
                onChange={(val) => {
                  if (/^[a-zA-Z0-9_.-]*$/.test(val)) {
                    setFullName(val);
                  }
                }}
                currVal={fullName === null ? me?.name : fullName}
                placeholder="Full Name"
              />
              <div>
                <div className={styles.datePickerWrapper}>
                  <DatePicker
                    mode="date"
                    title="Select date"
                    value={new Date(birth || me?.birth)}
                    locale={enUs}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(date) => setBirth(date)}
                  >
                    <List.Item arrow="horizontal">Date</List.Item>
                  </DatePicker>
                </div>
                <div style={{ marginTop: '-79px' }}>
                  <Input
                    placeholder="Date of Birth"
                    onChange={() => {}}
                    currVal={new Date(birth || me?.birth).toLocaleDateString()}
                    extra={<div className={styles.calendarIcon}><ReactSVG src="/input/calendar-icon.svg" /></div>}
                  />
                </div>
              </div>
              <Input
                onChange={(val) => setWeight(val)}
                currVal={weight === null ? me?.weight : weight}
                placeholder="Weight"
                extra={<div className={styles.extraText}>kg</div>}
                type="number"
                extraAfterText
              />
              <Input
                onChange={(val) => setHeight(val)}
                currVal={height === null ? me?.height : height}
                placeholder="Height"
                extra={<div className={styles.extraText}>cm</div>}
                type="number"
                extraAfterText
              />
              <Input
                onChange={() => {}}
                currVal={workOptions[employment || me?.workingStatus]}
                placeholder="Employment status"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEmpModal(true);
                }}
                extra={<div><ReactSVG src="/input/arrow-down-icon.svg" /></div>}
              />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                onClick={() => {
                  const values = {
                    name: fullName,
                    birth,
                    height,
                    weight,
                    workingStatus: employment,
                  };
                  dispatch(
                    saveDataAsync(
                      Object.keys(values)
                        .filter((k) => values[k])
                        .reduce((acc, k) => {
                          acc[k] = values[k];

                          return acc;
                        }, {}),
                    ),
                  );
                  setShowSuccess(true);
                }}
                filled
              >
                Save
              </Button>
            </div>
          </div>
        </Scroll>
      </PageWrapper>
    </PageBack>
  );
}

export default EditProfile;
