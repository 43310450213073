import React from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styles from './RewardBlock.module.scss';
import { getChallengesDataTemp } from '../../features/bamQuestionnaire/screens/screenSlice';

function RewardBlock({
  title, pointsRequired, onClick, imageUrl, totalPointsRequired, claimed,
}) {
  const { totalPoints = 0, points = 0 } = useSelector(getChallengesDataTemp);

  const blocked = !claimed && totalPoints < totalPointsRequired;
  const needMore = !claimed && !blocked && points < pointsRequired;

  return (
    <>
      <div className={styles.blockWrapper} onClick={onClick} role="button">
        <div className={styles.imagePointContainer}>
          <div className={styles.imageContainer}>
            <img className={`${styles.image} ${blocked ? styles.imageBlocked : ''}`} src={imageUrl} alt={title} />
          </div>
          <div className={styles.points}>
            {
              claimed && <ReactSVG src="/rewards/star-green-small-icon.svg" />
            }
            {
              !claimed && <ReactSVG src="/rewards/star-orange-filled-icon.svg" />
            }
            {pointsRequired}
          </div>
        </div>
        <div className={styles.bottomSection}>
          <div>
            {needMore && (
              <div className={styles.needMoreMessage}>
                {`Need ${pointsRequired - points} points to claim`}
              </div>
            )}
            {blocked && (
              <div className={styles.blockedMessage}>
                <ReactSVG className={styles.blockedMessageIcon} src="/rewards/lock-black-icon.svg" />
                {`Blocked, win ${totalPointsRequired} total points to CLAIM`}
              </div>
            )}
            <div className={`${styles.title} ${blocked ? styles.titleBlocked : ''}`}>
              {title}
              <div className={styles.arrowRight}>
                <ReactSVG src="/rewards/arrow-right.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

RewardBlock.propTypes = {
  title: PropTypes.string.isRequired,
  pointsRequired: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  totalPointsRequired: PropTypes.number.isRequired,
  claimed: PropTypes.bool,
};

RewardBlock.defaultProps = {
  claimed: false,
};

export default RewardBlock;
