import React, { useState } from 'react';
import 'antd-mobile/dist/antd-mobile.css';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Icon } from 'antd-mobile';
import { ReactSVG } from 'react-svg';
import styles from './Dashboard.module.scss';
import {
  getChallengesDataTemp,
  profileWasLoaded,
  selectProfile,
  selectProfileStatus,
} from '../bamQuestionnaire/screens/screenSlice';
import routes from '../../constants/routes';
import getUserAge from '../bamQuestionnaire/helpers/getUserAge';
import PageBack from '../../components/PageBack/PageBack';
import ChallengeBlock from '../../components/ChallengeBlock/ChallengeBlock';
import ChallengeModal from '../../components/ChallengeModal/ChallengeModal';
import Menu from '../../components/Menu/Menu';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import ArticleBlock from '../../components/ArticleBlock/ArticleBlock';
import { selectChallenges, selectFinishedChallenges, selectPoints } from './dashboardSlice';
import requestStatuses from '../../constants/requestStatuses';
import { S3_IMG_URL } from '../../config';
import {
  getArticlesAsync,
  getChallengesAsync, postChallengeSubmitAsync,
  selectArticlesData,
  selectChallengesData,
} from '../learn/learnSlice';
import SuccessScreenGreen from '../../components/SuccessScreenGreen/SuccessScreenGreen';

const challengesM = [
  {
    description: 'When considering reasonable bedtimes for an individual, the amount of sleep required to wake up feeling refreshed, or the sleep need, is taken into account. Sleep needs are often determined by age, although a person’s genetics and environment, medical, and behavioral conditions can affect their need.',
    title: 'Go to sleep at 11 or earlier',
    typeformId: 'tALKxvhh',
    points: '15',
    iconPath: '/challenges/sleep-em.svg',
    challengeId: '1',
  },
  {
    description: 'What do you know about nutrition?',
    title: 'Myth or fact?',
    typeformId: 'ZWcEuPRq',
    points: 15,
    iconPath: '/challenges/avocado.svg',
    challengeId: '2',
  },
];

function Dashboard() {
  const dispatch = useDispatch();

  const userProfile = useSelector(selectProfile);
  const profileLoaded = useSelector(profileWasLoaded);
  const status = useSelector(selectProfileStatus);
  const points = useSelector(selectPoints);
  const challenges = useSelector(selectChallenges);
  const fChallenges = useSelector(selectFinishedChallenges);
  const finishedChallenges = fChallenges?.split(',');
  const challengesData = useSelector(selectChallengesData);

  const pointsInfo = useSelector(getChallengesDataTemp);

  const articlesData = useSelector(selectArticlesData);
  if (!articlesData.status) {
    dispatch(getArticlesAsync());
  }

  const [showChallengeModal, setChallengeModal] = useState(false);
  const [currentChallenge, setCurrentChallenge] = useState(challengesM[0]);
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();

  if (!status) {
    return <div>Loading...</div>;
  }
  if (status === requestStatuses.IDLE && profileLoaded && !userProfile?.stress) {
    return <Redirect to={routes.BAM_QUEST} />;
  }
  if (!challengesData.status) {
    dispatch(getChallengesAsync());
  }

  const userAge = getUserAge(userProfile?.birth);
  const ageDiff = parseInt(getUserAge(userProfile?.birth)) - parseInt(userProfile?.biologicalAge);
  const younger = ageDiff >= 0;

  return (
    <PageBack>
      <div>
        <ChallengeModal
          onClose={() => {
            setChallengeModal(false);
          }}
          onSubmit={(event) => {
            dispatch(postChallengeSubmitAsync({ challengeId: currentChallenge.id, answerId: event.response_id }));
            setChallengeModal(false);
            setShowSuccess(true);
          }}
          display={showChallengeModal}
          challengeId={currentChallenge.id}
          description={currentChallenge.description}
          title={currentChallenge.title}
          typeformId={currentChallenge.subject?.typeFormId}
          points={currentChallenge.points}
          icon={currentChallenge.icon || <ReactSVG src={currentChallenge.iconPath} />}
          periodLength={parseInt(currentChallenge.time?.split(' ')[0] || '0', 10)}
          periodName={currentChallenge.time?.split(' ')[1]}
        />
      </div>
      <SuccessScreenGreen
        buttonText="Ok!"
        onClick={() => setShowSuccess(false)}
        title="Hoorray!"
        description="You have successfully completed the challenge"
        additionalContent={(
          <div className={styles.successChallengeInfo}>
            <div className={styles.successChallengeInfoIcon}>{currentChallenge.icon}</div>
            <div className={styles.successChallengeInfoTitle}>{currentChallenge.title}</div>
            <div className={styles.successChallengeInfoPoints}>
              <ReactSVG src="/challenges/star-green-small-icon.svg" />
              <div className={styles.successChallengeInfoPointsDivider} />
              {`+${currentChallenge.points} points`}
            </div>
          </div>
        )}
        customIcon="/challenges/flash-icon.svg"
        show={showSuccess}
      />
      <PageWrapper>
        <div className={styles.scrollableWrap}>
          <div className={styles.scrollable}>
            <div className={styles.hello}>
              Hello,
              {` ${userProfile?.name}`}
              !
            </div>
            <div className={styles.ageRound}>
              <div className={styles.ageContainer}>
                <div className={styles.realAge}>{userAge}</div>
                <div className={styles.ageText}>Current biological age</div>
                <div className={styles.ageValue}>
                  {userProfile?.biologicalAge ? `${userProfile?.biologicalAge}y` : '44y 2m'}
                </div>
              </div>
            </div>
            <div className={styles.bioAgeText}>
              This means you are
              {` ${Math.abs(ageDiff)} `}
              years
              {younger ? ' younger ' : ' older '}
              than your chronological age.
              {younger ? '' : ' But you can get younger!'}
            </div>
            <div className={styles.statisticsBlock}>
              <div className={styles.statistics}>
                <div className={styles.statCaption}>Balance</div>
                <div>
                  {`${pointsInfo.points || 0} `}
                  points
                </div>
              </div>
              <div className={styles.divider} />
              <div className={styles.statistics}>
                <div className={styles.statCaption}>Challenges</div>
                <div>
                  {`${pointsInfo.completedNum || 0} `}
                  completed
                </div>
              </div>
            </div>
            <div className={styles.categoryTitle}>
              <div className={styles.categoryName}>Join a challenge</div>
              <button className={styles.categoryButton} type="button" onClick={() => history.push(routes.LEARN)}>
                See All
                <Icon color="#9500AD" type="right" />
              </button>
            </div>
            {
              challengesData?.status === requestStatuses.IDLE &&
              challengesData?.challenges
                ?.filter(({ challenge: ch }) => (pointsInfo.completedIds || []).indexOf(ch.id) === -1)
                .slice(0, 2)
                .map(({ challenge: ch }) => (
                  <ChallengeBlock
                    key={Math.random()}
                    title={ch.title}
                    icon={ch.icon}
                    points={ch.points}
                    periodLength={parseInt(ch.time?.split(' ')[0] || '0')}
                    periodName={ch.time?.split(' ')[1]}
                    onClick={() => {
                      setChallengeModal(true);
                      setCurrentChallenge(ch);
                    }}
                  />
                ))
            }
            <div className={styles.categoryTitle}>
              <div className={styles.categoryName}>Articles/Tips</div>
              <button className={styles.categoryButton} type="button" onClick={() => history.push(`${routes.LEARN}?tab=1`)}>
                See All
                <Icon color="#9500AD" type="right" />
              </button>
            </div>
            {
              articlesData?.status === requestStatuses.IDLE &&
              [...(articlesData?.articles || [])]
                .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
                .slice(0, 2)
                .map((a) => (
                  <ArticleBlock
                    key={Math.random()}
                    category={a.category?.name}
                    categoryColor={a.category?.color}
                    image={`${S3_IMG_URL}/s-${a?.imageId}`}
                    title={a.title}
                    articleId={a.id}
                  />
                ))
            }
          </div>
        </div>
        <Menu />
      </PageWrapper>
    </PageBack>
  );
}

export default Dashboard;
