import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageBack.module.css';

function PageBack({ className, backgroundColor, children }) {
  const classNames = className ? `${styles.back} ${className}` : styles.back;

  return <div className={classNames} style={{ ...(backgroundColor ? { backgroundColor } : {}) }}>{children}</div>;
}

PageBack.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

PageBack.defaultProps = {
  backgroundColor: null,
  className: null,
};

export default PageBack;
