import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageWrapper.module.scss';

function PageWrapper({ children }) {
  return (
    <div className={styles.wrap}>
      {children}
    </div>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default PageWrapper;
