import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AboutScreen from './screens/AboutScreen/AboutScreen';
import NameScreen from './screens/NameScreen/NameScreen';
import GenderScreen from './screens/GenderScreen/GenderScreen';
import BirthScreen from './screens/BirthScreen/BirthScreen';
import WorkScreen from './screens/WorkScreen/WorkScreen';
import WeightScreen from './screens/WeightScreen/WeightScreen';
import SmokeScreen from './screens/SmokeScreen/SmokeScreen';
import ExerciseScreen from './screens/ExerciseScreen/ExerciseScreen';
import SitDownScreen from './screens/SitDownScreen/SitDownScreen';
import EatScreen from './screens/EatScreen/EatScreen';
import SleepScreen from './screens/SleepScreen/SleepScreen';
import DrinkScreen from './screens/DrinkScreen/DrinkScreen';
import StressScreen from './screens/StressScreen/StressScreen';
import ResultScreen from './screens/ResultScreen/ResultScreen';
import FigmaFrameScreen from './screens/FIgmaScreen/FigmaFrameScreen';
import { selectUser } from '../auth/authSlice';
import { getProfileAsync, selectProfile, selectProfileStatus } from './screens/screenSlice';
import Onboarding from './screens/Onboarding/Onboarding';
import PageBack from '../../components/PageBack/PageBack';
import PageWrapper from '../../components/PageWrapper/PageWrapper';

const steps = {
  [-1]: (onNext, onBack, data) => <Onboarding onNextStep={onNext} onBack={onBack} data={data} />,
  0: (onNext, onBack, data) => <AboutScreen onNextStep={onNext} onBack={onBack} data={data} />,
  1: (onNext, onBack, data) => <NameScreen onNextStep={onNext} onBack={onBack} data={data} />,
  2: (onNext, onBack, data) => <GenderScreen onNextStep={onNext} onBack={onBack} data={data} />,
  3: (onNext, onBack, data) => <WorkScreen onNextStep={onNext} onBack={onBack} data={data} />,
  4: (onNext, onBack, data) => <BirthScreen onNextStep={onNext} onBack={onBack} data={data} />,
  5: (onNext, onBack, data) => <WeightScreen onNextStep={onNext} onBack={onBack} data={data} />,
  6: (onNext, onBack, data) => <SmokeScreen onNextStep={onNext} onBack={onBack} data={data} />,
  7: (onNext, onBack, data) => <ExerciseScreen onNextStep={onNext} onBack={onBack} data={data} />,
  8: (onNext, onBack, data) => <SitDownScreen onNextStep={onNext} onBack={onBack} data={data} />,
  9: (onNext, onBack, data) => <EatScreen onNextStep={onNext} onBack={onBack} data={data} />,
  10: (onNext, onBack, data) => <SleepScreen onNextStep={onNext} onBack={onBack} data={data} />,
  11: (onNext, onBack, data) => <DrinkScreen onNextStep={onNext} onBack={onBack} data={data} />,
  12: (onNext, onBack, data) => <StressScreen onNextStep={onNext} onBack={onBack} data={data} />,
  13: (onNext, onBack, data) => <ResultScreen onNextStep={onNext} onBack={onBack} data={data} />,
  14: (onNext, onBack, data) => <FigmaFrameScreen onNextStep={onNext} onBack={onBack} data={data} />,
};

function BamQuestionnaire({ currentStep }) {
  const [step, setStep] = useState(currentStep);
  const [presettedStep, setPresetted] = useState(false);
  const [prevStepData, setPrevStepData] = useState();
  const user = useSelector(selectUser);
  if (!user?.userId) {
    return <Redirect to="/" />;
  }

  const profile = useSelector(selectProfile);

  if (profile && Object.keys(profile).length && !presettedStep) {
    const profileFields = {
      name: 1,
      gender: 2,
      workingStatus: 3,
      birth: 4,
      height: 5,
      weight: 5,
      smoking: 6,
      exercise: 7,
      dailySitDown: 8,
      diet: 9,
      sleep: 10,
      alcohol: 11,
      stress: 12,
    };
    Object.keys(profileFields).find((pfKey) => {
      if (!profile[pfKey]) {
        setStep(profileFields[pfKey] === 1 ? -1 : profileFields[pfKey]);
        setPresetted(true);

        return true;
      }

      setStep(13);
      setPresetted(true);

      return false;
    });
  }

  return (
    <PageBack>
      <PageWrapper style={{ display: step === 14 ? 'none' : 'block' }}>
        {steps[step](
          (data) => {
            if (steps[step + 1]) {
              setStep(step + 1);
              setPrevStepData(data);
            }
          },
          () => {
            if (steps[step - 1]) {
              setStep(step - 1);
            }
          },
          prevStepData,
        )}
      </PageWrapper>
    </PageBack>
  );
}

BamQuestionnaire.propTypes = {
  currentStep: PropTypes.number,
};

BamQuestionnaire.defaultProps = {
  currentStep: -1,
};

export default BamQuestionnaire;
