import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import routes from '../../constants/routes';
import { selectGetMeStatus, selectUser } from '../../features/auth/authSlice';
import VerifyEmail from '../../features/verifyEmail/VerifyEmail';
import requestStatuses from '../../constants/requestStatuses';

function PrivateRoute({
  path,
  children,
  exact,
}) {
  const user = useSelector(selectUser);
  const status = useSelector(selectGetMeStatus);
  const loggedIn = !!user?.userId;
  const activated = user?.isAccountActivated;

  const getContent = () => {
    if (!user && (!status || status === requestStatuses.LOADING)) {
      return <div>Loading...</div>;
    }
    if (loggedIn && activated) {
      return children;
    }
    if (loggedIn && !activated) {
      return <VerifyEmail />;
    }

    return <Redirect to={routes.WELCOME} />;
  };

  return (
    <Route path={path} exact={exact}>
      {getContent()}
    </Route>
  );
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  exact: false,
};

export default PrivateRoute;
