import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import styles from './Input.module.scss';

const validatorsByType = {
  number: (val) => /^[0-9]*$/.test(val),
};

function Input({
  placeholder,
  onChange,
  extra,
  currVal,
  type,
  error,
  onClick,
  extraAfterText,
}) {
  const [value, setValue] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const inputWidth = (currVal || placeholder)
    .toString()
    .replaceAll(' ', '')
    .length * 0.66 + 0.1;

  let inpRef = null;
  if (extraAfterText) {
    inpRef = createRef();
  }

  const eyeOpen = <ReactSVG src="/input/eye-open-icon.svg" />;
  const eyeClosed = <ReactSVG src="/input/eye-closed-icon.svg" />;

  return (
    <div
      className={styles.wrapNew}
      onClick={(e) => {
        onClick(e);
        if (inpRef && extraAfterText) {
          inpRef.current.focus();
        }
      }}
      onKeyPress={() => {}}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.placeholder}>{currVal ? placeholder : ''}</div>
      <div className={styles.inputContainer}>
        <input
          ref={inpRef}
          style={{ ...(extraAfterText ? { width: `${inputWidth}em` } : {}) }}
          size={extraAfterText ? (currVal || placeholder).length : -1}
          type={type === 'password' && showPassword ? 'text' : (type || 'text')}
          className={`${styles.inputNew} ${extraAfterText ? styles.inputNewNoFlex : ''}`}
          value={currVal}
          onKeyDown={(e) => {
            if (e.key.length > 1) {
              return e;
            }
            if (validatorsByType[type] && !validatorsByType[type](e.key)) {
              e.preventDefault();
              e.returnValue = false;
            }

            return e;
          }}
          onChange={(e) => {
            const val = e.target.value;
            onChange(val);
            setValue(val);
          }}
          placeholder={placeholder}
        />
        {type === 'password' && (
          <div
            onClick={() => setShowPassword(!showPassword)}
            role="button"
          >
            {showPassword ? eyeOpen : eyeClosed}
          </div>
        )}
        {
          ((extraAfterText && currVal) || !extraAfterText) && (
            <div className={styles.extra}>{extra}</div>
          )
        }
      </div>
      <div className={styles.errorMessage}>{error || ''}</div>
    </div>
  );
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  currVal: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  onClick: PropTypes.func,
  extraAfterText: PropTypes.bool,
};

Input.defaultProps = {
  placeholder: '',
  extra: null,
  currVal: '',
  type: 'text',
  error: null,
  onClick: () => {},
  extraAfterText: false,
};

export default Input;
