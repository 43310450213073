import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import {
  NavBar,
  Icon,
} from 'antd-mobile';
import { loginAsync, selectError, selectStatus } from './loginSlice';
import styles from './Login.module.css';
import Input from '../../components/Input/Input';
import PageBack from '../../components/PageBack/PageBack';
import Button from '../../components/Button/Button';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import routes from '../../constants/routes';
import requestStatuses from '../../constants/requestStatuses';
import { selectActivationToken, selectUser } from '../auth/authSlice';

function Login() {
  const dispatch = useDispatch();

  const history = useHistory();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const error = useSelector(selectError);
  const status = useSelector(selectStatus);

  const activationToken = useSelector(selectActivationToken);

  const user = useSelector(selectUser);

  if (user) {
    if (activationToken) {
      return <Redirect to={`/auth/activate/${activationToken}`} />;
    }

    return <Redirect to={routes.MAIN} />;
  }

  const onSubmit = () => {
    dispatch(loginAsync({ email: email.toLowerCase(), password }));
  };

  return (
    <PageBack>
      <PageWrapper>
        <NavBar
          className={styles.navBar}
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => history.push('/')}
        >
          Welcome back!
        </NavBar>
        <div className={styles.inputWrapper}>
          <div className={styles.input}>
            <Input
              placeholder="Email"
              onChange={(val) => setEmail(val)}
              currVal={email}
            />
          </div>
          <div className={styles.input}>
            <Input
              type="password"
              placeholder="Password"
              onChange={(val) => setPassword(val)}
              currVal={password}
            />
            <button
              className={styles.forgotPassword}
              type="button"
              onClick={() => {
                history.push(routes.FORGOT_PASSWORD);
              }}
            >
              Forgot password?
            </button>
          </div>
          <div
            className={status === requestStatuses.ERROR && [400, 404].indexOf(error) !== -1
              ? styles.errorMessage
              : styles.errorMessageHidden}
          >
            Invalid email and/or password
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={onSubmit}
            filled
          >
            Login
          </Button>
          <div className={styles.haveAccountWrapper}>
            Don't have an account?
            <button className={styles.inlineButton} type="button" onClick={() => history.push('/registration')}>Sign-up</button>
          </div>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default Login;
