import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NavBar, Icon } from 'antd-mobile';
import { useDispatch } from 'react-redux';
import styles from './ResetPassword.module.scss';
import PageBack from '../../components/PageBack/PageBack';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Input from '../../components/Input/Input';
import ButtonMain from '../../components/Button/Button';
import { resetPasswordAsync } from '../auth/authSlice';
import SuccessScreenGreen from '../../components/SuccessScreenGreen/SuccessScreenGreen';

function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { code } = useParams();

  // field values
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  // validators
  const validators = {
    password: {
      validator: (value) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value),
      message: 'Minimum eight characters, at least one letter, one number and one special character',
    },
    confirmPassword: {
      validator: (value) => value === password,
      message: 'Passwords must be the same',
    },
  };
  const requiredFields = {
    password, confirmPassword,
  };

  // error handling
  const [errors, setErrors] = useState({});
  const hasErrors = () => {
    const hasError = !!Object.keys(errors).find((e) => errors[e]);
    const fieldsValid = Object.keys(requiredFields).find((fN) => !requiredFields[fN]);

    return hasError || fieldsValid;
  };
  const checkField = (name, value) => {
    if (value && !validators[name].validator(value)) {
      setErrors({ ...errors, [name]: validators[name].message });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  // process values
  const onSubmit = () => {
    dispatch(resetPasswordAsync({ code, password }));
    setShowSuccessScreen(true);
  };

  return (
    <PageBack>
      <SuccessScreenGreen
        show={showSuccessScreen}
        onBack={() => history.push('/')}
        onClick={() => history.push('/')}
        buttonText="Ok!"
        description="Your password has been successfully changed"
        title="Password Changed"
        autoClose
      />
      <PageWrapper>
        <NavBar
          className={styles.navBar}
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => history.push('/login')}
        >
          Reset Password
        </NavBar>
        <div className={styles.inputWrapper}>
          <div className={styles.input}>
            <Input
              className={styles.input}
              placeholder="Password"
              type="password"
              onChange={(value) => {
                checkField('password', value);
                setPassword(value);
              }}
              currVal={password}
              error={errors.password}
            />
          </div>
          <div className={styles.input}>
            <Input
              className={styles.input}
              type="password"
              placeholder="Confirm Password"
              onChange={(value) => {
                checkField('confirmPassword', value);
                setConfirmPassword(value);
              }}
              currVal={confirmPassword}
              error={errors.confirmPassword}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <ButtonMain
            disabled={hasErrors()}
            filled
            onClick={onSubmit}
          >
            Reset
          </ButtonMain>
        </div>
      </PageWrapper>
    </PageBack>
  );
}

export default ResetPassword;
