import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageBack from '../../components/PageBack/PageBack';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Menu from '../../components/Menu/Menu';
import RewardBlock from '../../components/RewardBlock/RewardBlock';
import styles from './Rewards.module.scss';
import RewardDetailsModal from '../../components/RewardDetailsModal/RewardDetailsModal';
import Scroll from '../../components/Scroll/Scroll';
import CategoryTitle from '../../components/CategoryTitle/CategoryTitle';
import { getChallengesDataTemp, saveDataAsync } from '../bamQuestionnaire/screens/screenSlice';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import SuccessScreenGreen from '../../components/SuccessScreenGreen/SuccessScreenGreen';
import { selectUser } from '../auth/authSlice';
import routes from '../../constants/routes';
import ButtonWrapper from '../../components/ButtonWrapper/ButtonWrapper';
import QuestionModal from '../../components/QuestionModal/QuestionModal';

const rewards = [
  {
    id: 1,
    image: '/rewards/images/30-minute-horseback.jpeg',
    title: '30-Minute Private Horseback Riding Lesson',
    totalPoints: 10,
    points: 5,
    description: 'Horseback riding is a fine activity that allows individuals to learn this fine art. While it is no longer the necessary mode of transportation, it still allows fans of the mode to understand how to interact with the majestic beast, handle it',
  },
  {
    id: 2,
    image: '/rewards/images/one-month-gym.jpeg',
    title: 'One-Month Gym Membership for One',
    totalPoints: 10,
    points: 10,
    description: 'Enjoy our constantly varied workouts led by experienced coaches in a clean, welcoming space. Beginner through advanced levels.',
  },
  {
    id: 3,
    image: '/rewards/images/yoga-class.jpeg',
    title: 'Yoga Class 40% voucher',
    totalPoints: 10,
    points: 20,
    description: 'Yoga classes blend an emphasis on the physical and spiritual with a deep relaxation in a studio that’s inspired students over 50 years',
  },
  {
    id: 4,
    image: '/rewards/images/meditation.jpeg',
    title: 'Meditation, Yoga Teaching or Hatha Yoga Online Course',
    totalPoints: 10,
    points: 30,
    description: 'Emotional Freedom Technique will teach to tap on acupressure points while saying affirmations to calm stress and anxiety while reducing pain',
  },
  {
    id: 5,
    image: '/rewards/images/healthcheck.jpeg',
    title: 'Lifestyle Health Check via Blood Testing at I-Screen, Choice of Locations',
    totalPoints: 100,
    points: 25,
    description: 'Composition analysis tells clients with 98% accuracy their body fat percentage; the amount of fat & muscle they have, and where it is stored',
  },
];

function Rewards() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentReward, setCurrentReward] = useState(rewards[0]);
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showClaimQuestion, setShowClaimQuestion] = useState(false);

  const user = useSelector(selectUser);
  const challengeData = useSelector(getChallengesDataTemp);
  const { totalPoints = 0, points = 0, claimedRewardsIds = [] } = challengeData;

  const openModal = (reward) => () => {
    setShowDetails(true);
    setCurrentReward(reward);
  };

  return (
    <PageBack>
      <QuestionModal
        confirmText="Claim reward"
        onCancel={() => setShowClaimQuestion(false)}
        question="Claim reward?"
        description={`You are about to claim "${currentReward.title}".This reward has the value of ${currentReward.points} points, so you will have ${points - currentReward.points} points available left.`}
        cancelText="Cancel"
        show={showClaimQuestion}
        onConfirm={() => {
          dispatch(
            saveDataAsync({
              selectedLanguage: JSON.stringify({
                ...challengeData,
                points: (challengeData.points || 0) - currentReward.points,
                claimedRewardsIds: (challengeData.claimedRewardsIds || []).concat(currentReward.id),
              }),
            }),
          );
          setShowClaimQuestion(false);
          setShowDetails(false);
          setShowModal(true);
        }}
      />
      <SuccessScreenGreen
        buttonText="OK"
        onClick={() => setShowModal(false)}
        title="Reward Claimed"
        description=""
        additionalContent={(
          <>
            <div className={styles.successClaimText}>
              You’ve successfully claimed the reward.
              We have sent you additional information on your email
              <b>
                {` ${user?.email}`}
              </b>
            </div>
            <div className={styles.successClaimInfoContainer}>
              <div className={styles.successClaimInfoContainerTitle}>{currentReward.title}</div>
              <div className={styles.successClaimInfoContainerPoints}>
                <ReactSVG className={styles.successClaimInfoContainerPointsIcon} src="/rewards/star-green-big-icon.svg" />
                {`You used ${currentReward.points} points`}
              </div>
            </div>
          </>
        )}
        show={showModal}
      />
      <RewardDetailsModal
        title={currentReward.title}
        display={showDetails}
        onClick={() => {
          setShowClaimQuestion(true);
        }}
        onClose={() => setShowDetails(false)}
        imageUrl={currentReward.image}
        points={currentReward.points}
        pointsDiff={points - currentReward.points}
        totalPointsDiff={totalPoints - currentReward.totalPoints}
        totalPoints={currentReward.totalPoints}
        description={currentReward.description}
      />
      <Modal display={showInfo} onClose={() => setShowInfo(false)} height={70}>
        <div className={styles.infoContainer}>
          <div className={styles.dataContainer}>
            <div className={styles.moverContainer} onClick={() => setShowInfo(false)} role="button">
              <div className={styles.mover} />
            </div>
            <ReactSVG className={styles.pointsSystemIcon} src="/rewards/seccess-star.svg" />
            <div className={styles.pointsSystemText}>Points system</div>
            <div className={styles.totalPointsWonText}>Total points won</div>
            <div className={styles.sumOfPoints}>The sum of all the points you have won so far.</div>
            <div className={styles.totalPointsText}>Total points available</div>
          </div>
          <ButtonWrapper>
            <Button onClick={() => setShowInfo(false)}>Close</Button>
          </ButtonWrapper>
        </div>
      </Modal>
      <PageWrapper>
        <Scroll>
          <div className={styles.rewardsWrap}>
            <div className={styles.title}>Rewards</div>
            <div className={styles.pointsInfoBlock}>
              <div className={styles.pointsInfoBlockTitle}>
                <ReactSVG className={styles.pointsInfoBlockTitleIcon} src="/rewards/star-orange-icon.svg" />
                Point balance
                <div
                  className={styles.pointsInfoBlockTitleInfoButton}
                  onClick={() => setShowInfo(true)}
                  role="button"
                >
                  <ReactSVG src="/rewards/info-icon.svg" />
                </div>
              </div>
              <div className={styles.pointsSection}>
                <div className={styles.pointsSectionPartition}>
                  <div className={styles.pointsSectionPartitionTitle}>Total Won</div>
                  <div className={styles.pointsSectionPartitionPoints}>
                    {`${totalPoints} points`}
                  </div>
                </div>
                <div className={styles.pointsSectionPartition}>
                  <div className={styles.pointsSectionPartitionTitle}>Total Available</div>
                  <div className={styles.pointsSectionPartitionPoints}>
                    {`${points} points`}
                  </div>
                </div>
              </div>
            </div>
            <CategoryTitle
              title="Rewards for you"
              buttonText="claimed"
              onClick={() => history.push(routes.REWARDS_CLAIMED)}
            />
            <div>
              {rewards.filter((r) => claimedRewardsIds.indexOf(r.id) === -1).map((r) => (
                <RewardBlock
                  key={Math.random()}
                  pointsRequired={r.points}
                  title={r.title}
                  onClick={openModal(r)}
                  imageUrl={r.image}
                  totalPointsRequired={r.totalPoints}
                />
              ))}
            </div>
          </div>
        </Scroll>
        <Menu />
      </PageWrapper>
    </PageBack>
  );
}

export default Rewards;
