import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  getChallengesAsync,
  getCompletedChallengesAsync, postChallengeSubmitAsync,
  selectChallengesData,
  selectCompletedChallengesData,
} from '../learnSlice';
import PageBack from '../../../components/PageBack/PageBack';
import PageWrapper from '../../../components/PageWrapper/PageWrapper';
import HeaderNav from '../../../components/HeaderNav/HeaderNav';
import ChallengeBlock from '../../../components/ChallengeBlock/ChallengeBlock';
import styles from './CompletedChallenges.module.scss';
import { selectFinishedChallenges } from '../../dashboard/dashboardSlice';
import ChallengeModal from '../../../components/ChallengeModal/ChallengeModal';
import { getChallengesDataTemp } from '../../bamQuestionnaire/screens/screenSlice';

function CompletedChallenges() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentChallenge, setCurrentChallenge] = useState({});
  const [showChallengeModal, setChallengeModal] = useState(false);

  // const challengesData = useSelector(selectCompletedChallengesData);
  const challengesData = useSelector(selectChallengesData);

  const pointsInfo = useSelector(getChallengesDataTemp);

  if (!challengesData.status) {
    // dispatch(getCompletedChallengesAsync());
    dispatch(getChallengesAsync());
  }

  const fChallenges = useSelector(selectFinishedChallenges);
  const finishedChallenges = fChallenges?.split(',');

  const challenges = challengesData?.challenges
    ?.filter(({ challenge: ch }) => (pointsInfo.completedIds || []).indexOf(ch.id) !== -1);

  return (
    <PageBack>
      <ChallengeModal
        onClose={() => {
          setChallengeModal(false);
        }}
        display={showChallengeModal}
        challengeId={currentChallenge.id}
        description={currentChallenge.description}
        title={currentChallenge.title}
        typeformId={currentChallenge.subject?.typeFormId}
        points={currentChallenge.points}
        icon={currentChallenge.icon || <ReactSVG src={currentChallenge.iconPath} />}
        finished
        periodLength={currentChallenge.time?.split(' ')[0]}
        periodName={currentChallenge.time?.split(' ')[1]}
      />
      <PageWrapper>
        <HeaderNav
          onBack={() => history.goBack()}
          title={`Challenges completed (${challenges.length})`}
        />
        {!!challenges.length && (
          <div className={styles.date}>
            {new Date().toLocaleDateString()}
          </div>
        )}
        {
          challenges.map(({ challenge: ch }) => (
            <ChallengeBlock
              title={ch.title}
              points={ch.points}
              icon={ch.icon}
              finished
              onClick={() => {
                setCurrentChallenge(ch);
                setChallengeModal(true);
              }}
            />
          ))
        }
      </PageWrapper>
    </PageBack>
  );
}

export default CompletedChallenges;
