import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './ResultScreen.module.scss';
import { getProfileAsync, selectProfile } from '../screenSlice';
import getUserAge from '../../helpers/getUserAge';
import Button from '../../../../components/Button/Button';
import ageBackSvg from './age-back.svg';

function ResultScreen({ onNextStep }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector(selectProfile);
  if (!profile.biologicalAge) {
    dispatch(getProfileAsync());
  }

  const ageDiff = parseInt(getUserAge(profile?.birth)) - parseInt(profile?.biologicalAge);

  return (
    <div className={styles.wrap}>
      <div className={styles.contentWrapper}>
        <div className={styles.ageBack} style={{ backgroundImage: `url(${ageBackSvg})` }}>
          <div className={styles.ageContainer}>
            <div className={styles.ageText}>Current biological age</div>
            <div className={styles.ageValue}>{`${profile?.biologicalAge} years` || '44y 2m'}</div>
          </div>
        </div>
        <div className={styles.title}>Quiz Result</div>
        <div className={styles.content}>
          Your biological age is
          {` ${profile?.biologicalAge} `}
          years. This means you are
          {` ${Math.abs(ageDiff)} `}
          years
          {ageDiff > 0 ? ' younger ' : ' older '}
          than your chronological age. But you can get younger!
          With the help of our recommendations, we will be able to maintain and improve your health.
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            history.push('/');
            dispatch(getProfileAsync());
          }}
          filled
        >
          Lets Start!
        </Button>
      </div>
    </div>
  );
}

ResultScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
};

export default ResultScreen;
