import axios from 'axios';
import { SERVER_URL } from '../config';
import methods from '../constants/httpMethods';

export const axiosInstance = axios.create({
  baseURL: `${SERVER_URL}/api/v2`,
  headers: {
    'X-App-Type': 'mobile',
    'Bypass-Tunnel-Reminder': '*',
  },
});

export const apiRequest = async (path, method, body, params) => {
  const { token, includeDeviceInfo } = params || {};
  try {
    const response = await axiosInstance.request({
      url: path,
      method,
      data: { ...(body || {}), ...(includeDeviceInfo ? { deviceInfo: 'brrr', deviceId: '123' } : {}) },
      headers: {
        ...(token ? { 'X-Session-Id': token } : {}),
      },
      transformResponse: [function transform(data) {
        return { success: true, data: data ? JSON.parse(data) : null };
      }],
    });

    return response.data;
  } catch (e) {
    return { success: false, error: e.message, code: e.response?.data?.data?.statusCode };
  }
};

export const apiRequestGet = (path, params) => apiRequest(path, methods.GET, null, params);
