import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import ChallengeBlock from '../../components/ChallengeBlock/ChallengeBlock';
import ChallengeModal from '../../components/ChallengeModal/ChallengeModal';
import PageBack from '../../components/PageBack/PageBack';
import Menu from '../../components/Menu/Menu';
import styles from './Learn.module.scss';
import TabView from '../../components/TabView/TabView';
import ArticleBlock from '../../components/ArticleBlock/ArticleBlock';
import {
  getArticlesAsync, getChallengesAsync, postChallengeSubmitAsync, selectArticlesData, selectChallengesData,
} from './learnSlice';
import requestStatuses from '../../constants/requestStatuses';
import { selectChallenges, selectFinishedChallenges } from '../dashboard/dashboardSlice';
import { S3_IMG_URL } from '../../config';
import SuccessScreenGreen from '../../components/SuccessScreenGreen/SuccessScreenGreen';
import routes from '../../constants/routes';
import {getChallengesDataTemp} from "../bamQuestionnaire/screens/screenSlice";

const challengesM = [
  {
    description: 'When considering reasonable bedtimes for an individual, the amount of sleep required to wake up feeling refreshed, or the sleep need, is taken into account. Sleep needs are often determined by age, although a person’s genetics and environment, medical, and behavioral conditions can affect their need.',
    title: 'Go to sleep at 11 or earlier',
    typeformId: 'tALKxvhh',
    points: '15',
    iconPath: '/challenges/sleep-em.svg',
    challengeId: '1',
  },
  {
    description: 'What do you know about nutrition?',
    title: 'Myth or fact?',
    typeformId: 'ZWcEuPRq',
    points: 15,
    iconPath: '/challenges/avocado.svg',
    challengeId: '2',
  },
];

function Learn() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showChallengeModal, setChallengeModal] = useState(false);
  const [currentChallenge, setCurrentChallenge] = useState(challengesM[0]);
  const [showSuccess, setShowSuccess] = useState(false);
  const articlesData = useSelector(selectArticlesData);
  const challengesData = useSelector(selectChallengesData);
  const fChallenges = useSelector(selectFinishedChallenges);
  const finishedChallenges = fChallenges?.split(',');

  const pointsInfo = useSelector(getChallengesDataTemp);

  // TODO: resolve this
  const challenges = useSelector(selectChallenges);
  const activeChallenges = null;

  if (!articlesData.status) {
    dispatch(getArticlesAsync());
  }
  if (!challengesData.status) {
    dispatch(getChallengesAsync());
  }

  const tabs = [
    {
      name: 'challenges',
      title: 'Challenges',
      component: (
        <div>
          <div className={styles.join}>
            <div className={styles.joinTitle}>Join a challenge!</div>
            <div
              className={styles.completedButton}
              role="button"
              onClick={() => history.push(routes.CHALLENGES_COMPLETED)}
            >
              completed
              <ReactSVG className={styles.completedArrow} src="/challenges/arrow-right-main.svg" />
            </div>
          </div>
          {
            challengesData?.status === requestStatuses.IDLE &&
            challengesData?.challenges
              ?.filter(({ challenge: ch }) => (pointsInfo.completedIds || []).indexOf(ch.id) === -1)
              .map(({ challenge: ch }) => (
                <ChallengeBlock
                  title={ch.title}
                  icon={ch.icon}
                  points={ch.points}
                  periodLength={ch.time?.split(' ')[0]}
                  periodName={ch.time?.split(' ')[1]}
                  onClick={() => {
                    setChallengeModal(true);
                    setCurrentChallenge(ch);
                  }}
                />
              ))
          }
        </div>
      ),
    },
    {
      name: 'articles',
      title: 'Articles',
      component: (
        <div>
          {
            articlesData?.status === requestStatuses.IDLE &&
            [...(articlesData?.articles || [])]
              .sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
              .map((a) => (
                <ArticleBlock
                  key={Math.random()}
                  category={a.category?.name}
                  categoryColor={a.category?.color}
                  image={`${S3_IMG_URL}/s-${a?.imageId}`}
                  title={a.title}
                  articleId={a.id}
                />
              ))
          }
        </div>
      ),
    },
  ];

  return (
    <PageBack>
      <SuccessScreenGreen
        buttonText="Ok!"
        onClick={() => setShowSuccess(false)}
        title="Hoorray!"
        description="You have successfully completed the challenge"
        additionalContent={(
          <div className={styles.successChallengeInfo}>
            <div className={styles.successChallengeInfoIcon}>{currentChallenge.icon}</div>
            <div className={styles.successChallengeInfoTitle}>{currentChallenge.title}</div>
            <div className={styles.successChallengeInfoPoints}>
              <ReactSVG src="/challenges/star-green-small-icon.svg" />
              <div className={styles.divider} />
              {`+${currentChallenge.points} points`}
            </div>
          </div>
        )}
        customIcon="/challenges/flash-icon.svg"
        show={showSuccess}
      />
      <div>
        <ChallengeModal
          onClose={() => {
            setChallengeModal(false);
          }}
          onSubmit={(event) => {
            dispatch(postChallengeSubmitAsync({ challengeId: currentChallenge.id, answerId: event.response_id }));
            setChallengeModal(false);
            setShowSuccess(true);
          }}
          display={showChallengeModal}
          challengeId={currentChallenge.id}
          description={currentChallenge.description}
          title={currentChallenge.title}
          typeformId={currentChallenge.subject?.typeFormId}
          points={currentChallenge.points}
          icon={currentChallenge.icon || <ReactSVG src={currentChallenge.iconPath} />}
          periodLength={currentChallenge.time?.split(' ')[0]}
          periodName={currentChallenge.time?.split(' ')[1]}
        />
      </div>
      <PageWrapper>
        <div className={styles.scrollableWrap}>
          <div className={styles.scrollable}>
            <div className={styles.title}>Learn</div>
            <TabView tabs={tabs} />
          </div>
        </div>
        <Menu />
      </PageWrapper>
    </PageBack>
  );
}

export default Learn;
