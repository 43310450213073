import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './TabView.module.scss';

function TabView({ tabs }) {
  const history = useHistory();
  const matches = (history.location?.search || '').match(/\?tab=(\d)/);
  const activeTab = matches?.length === 2 ? matches[1] : 0;
  const activeTabName = tabs[activeTab]?.name || tabs[0]?.name || null;


  const [view, setView] = useState(activeTabName);

  return (
    <div>
      <div className={styles.tabs}>
        {tabs.map(({ name, title }, idx) => (
          <div
            key={Math.random()}
            className={name === view ? styles.tabActive : styles.tab}
            role="button"
            onClick={() => {
              setView(name);
              history.push(`?tab=${idx}`);
            }}
            onKeyPress={() => {}}
            tabIndex={-1}
          >
            {title}
          </div>
        ))}
      </div>
      {
        tabs.length && (
          <div>
            {tabs.find((t) => t.name === view).component}
          </div>
        )
      }
    </div>
  );
}

TabView.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    component: PropTypes.element.isRequired,
  })),
};

TabView.defaultProps = {
  tabs: [],
};

export default TabView;
