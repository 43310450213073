import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './NameScreen.module.css';
import dWhiteSpace from '../../../../components/dWhiteSpace';
import Input from '../../../../components/Input/Input';
import { saveDataAsync, selectProfile } from '../screenSlice';
import Button from '../../../../components/Button/Button';

function NameScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [name, setName] = useState(me?.name);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      {dWhiteSpace(2, 'xl')}
      <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
        <div className={styles.navBarCustomLeft}>
          <Icon color="#30353F" type="left" />
          <div className={styles.navBarCustomText}>Back</div>
        </div>
        <div className={styles.navBarCustomCenter}>1/12</div>
        <div className={styles.navBarCustomRight} />
      </div>
      <div>
        <Progress
          className={styles.progress}
          barStyle={{
            border: '4px solid #9500AD',
            borderRadius: '4px',
          }}
          percent={8.3}
          position="normal"
        />
      </div>
      <div className={styles.logoWrapper}>
        <ReactSVG className={styles.logo} src="/person-icon.svg" />
      </div>
      <div className={styles.question}>What is your name?</div>
      <div className={styles.inputWrapper}>
        <Input
          placeholder="Name"
          onChange={(val) => {
            if (/^[a-zA-Z0-9_.-]*$/.test(val)) {
              setName(val);
            }
          }}
          currVal={name}
        />
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep({ name });
            dispatch(saveDataAsync({ name }));
          }}
          disabled={!name}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

NameScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default NameScreen;
