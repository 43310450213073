import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './ArticleBlock.module.scss';

function ArticleBlock({
  title, category, categoryColor, image, articleId, slug,
}) {
  const history = useHistory();

  return (
    <div
      className={styles.blockWrap}
      onClick={() => {
        history.push(`/article/${articleId}`);
      }}
      role="button"
      onKeyPress={() => {}}
      tabIndex={-1}
    >
      <div className={styles.titleBlock}>
        <div className={styles.title}>{title}</div>
        <div className={styles.categoryWrap}>
          <div className={styles.indicator} style={{ background: categoryColor }} />
          <div className={styles.category} style={{ color: categoryColor }}>{category || 'Vitaance'}</div>
        </div>
      </div>
      <div className={styles.imageBlock}>
        <img className={styles.image} src={image} alt="Article" />
      </div>
    </div>
  );
}

ArticleBlock.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  articleId: PropTypes.number.isRequired,
  slug: PropTypes.string,
  categoryColor: PropTypes.string.isRequired,
};

ArticleBlock.defaultProps = {
  slug: '',
};

export default ArticleBlock;
