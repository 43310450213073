import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BottomDialog from '../BottomDialog/BottomDialog';
import styles from './SelectModal.module.scss';

function SelectModal({
  show, cancelText, title, options, onSelect, onCancel, selected,
}) {
  const [activeOption, setActiveOption] = useState(options.find((o) => o.active)?.key);

  return (
    <BottomDialog show={show} onClose={onCancel}>
      <div className={styles.dialogWrap}>
        <div className={styles.title}>{title}</div>
        <div className={styles.options}>
          {options.map((o) => (
            <div
              key={Math.random()}
              className={o.key === selected ? styles.activeOption : styles.option}
              onClick={() => {
                onSelect(o.key);
                onCancel();
              }}
              onKeyPress={() => {}}
              tabIndex={-1}
              role="button"
            >
              {o.value}
            </div>
          ))}
        </div>
        <div
          className={styles.cancel}
          onClick={onCancel}
          onKeyPress={() => {}}
          tabIndex={-1}
          role="button"
        >
          {cancelText}
        </div>
      </div>
    </BottomDialog>
  );
}

SelectModal.propTypes = {
  show: PropTypes.bool,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

SelectModal.defaultProps = {
  show: false,
  cancelText: 'Cancel',
  title: '',
};

export default SelectModal;
