import React from 'react';
import PropTypes from 'prop-types';
import styles from './CenterDialog.module.scss';

function CenterDialog({ children, show, onClose }) {
  return (
    <div
      className={styles.dialogWrap}
      style={{ ...(show ? {} : { display: 'none' }) }}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      onKeyPress={() => {}}
      role="button"
      tabIndex={-1}
    >
      <div className={styles.dialogContainer}>
        <div className={styles.dialog}>
          {children}
        </div>
      </div>
    </div>
  );
}

CenterDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

CenterDialog.defaultProps = {
  show: false,
};

export default CenterDialog;
