import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Progress } from 'antd-mobile';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './WorkScreen.module.scss';
import SelectButtons from '../../../../components/SelectButtons/SelectButtons';
import { saveDataAsync, selectProfile } from '../screenSlice';
import Button from '../../../../components/Button/Button';

const workOptions = {
  'self-employed': 'Self-employed',
  'full-time': 'Employee-full time',
  'part-time': 'Employee - part time',
  'dont-work': 'I don\'t work',
  homemaker: 'Full-time homemaker',
  retired: 'I am retired',
};

function WorkScreen({ onNextStep, onBack }) {
  const me = useSelector(selectProfile);

  const [selectedValue, setValue] = useState(me?.workingStatus);
  const dispatch = useDispatch();

  return (
    <div className={styles.wrap}>
      <div className={styles.contentWrapper}>
        <div className={styles.navBarCustom} tabIndex={0} role="button" onClick={onBack} onKeyPress={() => {}}>
          <div className={styles.navBarCustomLeft}>
            <Icon color="#30353F" type="left" />
            <div className={styles.navBarCustomText}>Back</div>
          </div>
          <div className={styles.navBarCustomCenter}>3/12</div>
          <div className={styles.navBarCustomRight} />
        </div>
        <div>
          <Progress
            className={styles.progress}
            barStyle={{
              border: '4px solid #9500AD',
              borderRadius: '4px',
            }}
            percent={24.9}
            position="normal"
          />
        </div>
        <div className={styles.logoWrapper}>
          <ReactSVG className={styles.logo} src="/work-icon.svg" />
        </div>
        <div className={styles.question}>
          What is your working status?
        </div>
        <div className={styles.inputWrapper}>
          <SelectButtons
            options={Object.keys(workOptions).map((value) => ({ value, name: workOptions[value] }))}
            onSelect={setValue}
            selected={selectedValue}
          />
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <Button
          onClick={() => {
            onNextStep();
            dispatch(saveDataAsync({ workingStatus: selectedValue }));
          }}
          disabled={!selectedValue}
          filled
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

WorkScreen.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  data: PropTypes.shape({ name: PropTypes.string }),
};

WorkScreen.defaultProps = {
  data: { name: 'User' },
};

export default WorkScreen;
