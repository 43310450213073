import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import PageBack from '../../components/PageBack/PageBack';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import Menu from '../../components/Menu/Menu';
import styles from './Profile.module.scss';
import { logOutAsync, selectUser } from '../auth/authSlice';
import { selectProfile } from '../bamQuestionnaire/screens/screenSlice';
import SelectModal from '../../components/SelectModal/SelectModal';
import routes from '../../constants/routes';
import QuestionModal from '../../components/QuestionModal/QuestionModal';

function Profile() {
  const history = useHistory();
  const dispatch = useDispatch();

  const me = useSelector(selectUser);
  const profile = useSelector(selectProfile);

  const [currentLang, setCurrentLang] = useState('en');
  const [showLangModal, setLangModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <PageBack>
      <QuestionModal
        confirmText="Log Out"
        onCancel={() => setShowLogoutModal(false)}
        question="Are you sure you want to Log Out?"
        cancelText="Cancel"
        show={showLogoutModal}
        onConfirm={() => {
          dispatch(logOutAsync());
        }} // logout here
      />
      <SelectModal
        options={[
          {
            key: 'en',
            value: 'English (English)',
          },
          {
            key: 'es',
            value: 'Español (Spanish)',
          },
        ]}
        title="Choose your preferred language"
        show={showLangModal}
        cancelText="Cancel"
        onCancel={() => setLangModal(false)}
        onSelect={(key) => setCurrentLang(key)}
        selected={currentLang}
      />
      <PageWrapper>
        <div className={styles.backHeader}>
          <div className={styles.headerTitle}>Profile</div>
          <div className={styles.cardWrap}>
            <div className={styles.card}>
              <div className={styles.topBlock}>
                <div className={styles.userInfo}>
                  <div className={styles.photo}>
                    <img src="/temp/avatar1.jpeg" alt="user" />
                  </div>
                  <div className={styles.name}>
                    {profile?.name}
                  </div>
                  <div className={styles.email}>
                    {me?.email}
                  </div>
                </div>
              </div>
              <div className={styles.buttonList}>
                <div
                  className={styles.menuItem}
                  onClick={() => history.push(routes.EDIT_PROFILE)}
                  onKeyPress={() => {}}
                  tabIndex={-1}
                  role="button"
                >
                  <div className={styles.icon}>
                    <ReactSVG src="/profile/icons/pencil.svg" />
                  </div>
                  <div className={styles.title}>
                    Edit Profile
                  </div>
                  <div className={styles.arrow}>
                    <ReactSVG src="/profile/icons/arrow_right.svg" />
                  </div>
                </div>
                <div
                  className={styles.menuItem}
                  onClick={() => history.push(routes.CHANGE_PASSWORD)}
                  onKeyPress={() => {}}
                  tabIndex={-1}
                  role="button"
                >
                  <div className={styles.icon}>
                    <ReactSVG src="/profile/icons/lock.svg" />
                  </div>
                  <div className={styles.title}>
                    Change Password
                  </div>
                  <div className={styles.arrow}>
                    <ReactSVG src="/profile/icons/arrow_right.svg" />
                  </div>
                </div>
                <div className={styles.menuItem}>
                  <div className={styles.icon}>
                    <ReactSVG src="/profile/icons/paper.svg" />
                  </div>
                  <div className={styles.title}>
                    Terms & Conditions
                  </div>
                  <div className={styles.arrow}>
                    <ReactSVG src="/profile/icons/arrow_right.svg" />
                  </div>
                </div>
                <div className={styles.menuItem}>
                  <div className={styles.icon}>
                    <ReactSVG src="/profile/icons/question.svg" />
                  </div>
                  <div
                    className={styles.title}
                    onClick={() => window.open('https://ayuda.vitaance.com/hc/es')}
                    onKeyPress={() => {}}
                    tabIndex={-1}
                    role="button"
                  >
                    Help Center
                  </div>
                  <div className={styles.arrow}>
                    <ReactSVG src="/profile/icons/arrow_right.svg" />
                  </div>
                </div>
                <div
                  className={styles.menuItem}
                  onClick={() => setLangModal(true)}
                  onKeyPress={() => {}}
                  tabIndex={-1}
                  role="button"
                >
                  <div className={styles.icon}>
                    <ReactSVG src="/profile/icons/lang.svg" />
                  </div>
                  <div className={styles.title}>
                    Language
                  </div>
                  <div className={styles.arrow}>
                    <ReactSVG src="/profile/icons/arrow_right.svg" />
                  </div>
                </div>
                <div className={styles.logout}>
                  <div className={styles.icon}>
                    <ReactSVG src="/profile/icons/out.svg" />
                  </div>
                  <div
                    className={styles.menuItem}
                    onClick={() => setShowLogoutModal(true)}
                    onKeyPress={() => {}}
                    tabIndex={-1}
                    role="button"
                  >
                    Log out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Menu />
      </PageWrapper>
    </PageBack>
  );
}

export default Profile;
